import React from "react";
import { formatValue } from "core/components/AI/Graph/Graph";
import {
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Size } from "core/types/ViewInsight";

const MAX_ROW_COUNT = 5;

const COLUMN_COLUMN_PER_SIZE = {
  [Size.Small]: 2,
  [Size.Medium]: 3,
  [Size.Large]: 4,
};

export const SimpleTable: React.FC<{
  columns: { name: string; type: string; label?: string }[];
  data: { [key: string]: string | number | object }[];
  size?: Size;
}> = ({ columns, data, size = Size.Small }) => {
  if (
    (columns.length === 1 && columns[0].type.includes("Int")) ||
    columns[0].type.includes("Float")
  ) {
    return (
      <Center h="full" w="full" ml={3} mb={4}>
        <Flex align="center" direction="column">
          <Text fontWeight="medium" noOfLines={1} fontSize="5xl">
            {Number(Object.values(data[0])[0]).toLocaleString()}
          </Text>
        </Flex>
      </Center>
    );
  }
  return (
    <TableContainer h="full" w="full" ml={6}>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {columns
              ?.slice(0, COLUMN_COLUMN_PER_SIZE[size])
              .map((column, index) => {
                return (
                  <Th
                    pl={0}
                    key={index}
                    maxW={`${315 / columns.slice(0, COLUMN_COLUMN_PER_SIZE[size]).length}px`}
                  >
                    <p className="text-lowercase max-w-full truncate text-xs font-medium capitalize text-black">
                      {(
                        column.label || column.name.split(`_`).join(" ")
                      ).toLowerCase()}
                    </p>
                  </Th>
                );
              })}
          </Tr>
        </Thead>
        <Tbody>
          {data?.slice(0, MAX_ROW_COUNT).map((row, rowIndex) => {
            return (
              <Tr key={rowIndex}>
                {columns
                  .slice(0, COLUMN_COLUMN_PER_SIZE[size])
                  .map((column, colIndex) => {
                    const cellValue = row[column.name];
                    return (
                      <Td
                        maxW={`${315 / columns.slice(0, COLUMN_COLUMN_PER_SIZE[size]).length}px`}
                        pl={0}
                        key={colIndex}
                      >
                        <Text noOfLines={1} fontSize="xs">
                          {typeof cellValue === "object"
                            ? JSON.stringify(cellValue)
                            : formatValue(cellValue as string)}
                        </Text>
                      </Td>
                    );
                  })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
