import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ViewContainer } from "modules/Views/Container";
import { AddMenu } from "modules/Views/AddMenu";
import { GettingStartedBanner } from "modules/Home/GettingStartedBanner";
import { AnimatePresence, motion } from "framer-motion";
import { IAudience, IAudienceFilters } from "core/types/Audience";
import { AudienceFilters } from "core/modules/audience/Filters/AudienceFilters";
import { AddAudienceMenu } from "core/modules/audience/Filters/AddAudienceMenu";
import { useUpdateViewMutation } from "core/models/views";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import {
  useGetAudienceQuery,
  useUpdateAudienceMutation,
} from "core/models/audiences";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import useFlag from "core/hooks/useFlag";
import { useAppObjects } from "core/hooks/useAppObjects";
import { TwEditable } from "core/design-system/components/TwEditable";
import { AND, JoinOperatorValue } from "core/constants/report-setup";
import { SQL_EDITOR } from "core/constants/features";
import { PageContainer } from "core/components/PageContainer";
import { EmojiPicker } from "core/components/EmojiPicker";
import {
  AdjustmentsVerticalIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Badge, Button, Flex, Skeleton, Tooltip } from "@chakra-ui/react";

export function getFilterCount(audienceFilters: IAudienceFilters) {
  return audienceFilters.filterGroups.reduce((count, group) => {
    return count + group.filters.length;
  }, 0);
}

export const View: React.FC = () => {
  const { appId, viewId } = useParams();
  const dispatch = useDispatch();
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const { shouldShowGettingStartedBanner } = useOnboardingChecklist();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const [showAudienceFilters, setShowAudienceFilters] = useState(() => {
    const stored = localStorage.getItem("showAudienceFilters");
    return stored !== null ? JSON.parse(stored) : true;
  });
  const { view, isLoadingViews, isFetchingViews } = useViews({
    location: ViewLocation.Home,
    pinned: false,
  });
  const [audience, setAudience] = useState<IAudience | null>(null);
  const { userAppObject } = useAppObjects();
  const {
    data,
    isFetching: isFetchingAudience,
    isLoading: isLoadingAudience,
  } = useGetAudienceQuery({
    appId: Number(appId),
    audienceId: view?.audience?.id as number,
  });

  const [updateAudience] = useUpdateAudienceMutation();
  const [audienceFilters, setAudienceFilters] = useState<IAudienceFilters>({
    filterGroups: [],
    joinOperator: AND as unknown as JoinOperatorValue,
  });

  const hasSQLEditor = useFlag(SQL_EDITOR);

  const isAudienceUpdated =
    JSON.stringify(audienceFilters) !==
    JSON.stringify({
      filterGroups: view?.audience?.filterGroups,
      joinOperator: view?.audience?.joinOperator,
    });

  const [updateView] = useUpdateViewMutation();

  const onAddAudience = (audience: IAudience) => {
    if (!view) return;

    updateView({
      id: Number(view.id),
      appId: Number(appId),
      audienceId: Number(audience.id),
    });
  };

  const onRemoveAudience = () => {
    if (!view) return;

    setAudience(null);
    setAudienceFilters({
      filterGroups: [],
      joinOperator: AND as unknown as JoinOperatorValue,
    });

    updateView({
      id: Number(view.id),
      appId: Number(appId),
      audienceId: undefined,
    });
  };

  useEffect(() => {
    if (data) setAudience(data);
  }, [data]);

  useEffect(() => {
    if (!audience) return;

    setAudienceFilters({
      filterGroups: audience.filterGroups,
      joinOperator: audience.joinOperator,
    });
  }, [audience]);

  useEffect(() => {
    localStorage.setItem(
      "showAudienceFilters",
      JSON.stringify(showAudienceFilters),
    );
  }, [showAudienceFilters]);

  function onSaveAudience() {
    if (!audience) return;

    updateAudience({
      audienceId: Number(audience.id),
      appId: Number(appId),
      audienceFilters,
    });
  }

  function handleEmojiClick(emojiObject: { emoji: string }, viewId: number) {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emojiObject.emoji,
    });
  }

  function onUpdateViewName(title: string) {
    if (view?.id)
      updateView({ id: Number(view.id), appId: Number(appId), name: title });
  }

  return (
    <PageContainer
      maxW="1106px"
      h="100vh"
      bgColor={isEditing ? "bg-gray-50" : "bg-white"}
      shadow={!isEditing}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <div className="flex">
              <EmojiPicker
                emoji={view?.emoji ? view?.emoji : "🏠"}
                handleEmojiClick={(e) => handleEmojiClick(e, Number(view?.id))}
                buttonProps={{ size: "normal", px: 2 }}
                textProps={{ fontSize: "3xl" }}
              />
            </div>
            <div className="flex">
              {view && (
                <TwEditable
                  key={view.name}
                  className={"text-2xl font-semibold"}
                  inputClassName={"text-2xl font-semibold"}
                  defaultValue={view.name}
                  placeholder={"Untitled view"}
                  fontWidth={14}
                  onSubmit={onUpdateViewName}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {hasSQLEditor ? (
            <AddMenu
              onAddInsightClick={() => {
                setShowAddModal(!showAddModal);
                dispatch(toggleEdit());
              }}
              onAddSQLClick={() => {
                setShowSQLModal(!showSQLModal);
                dispatch(toggleEdit());
              }}
            />
          ) : (
            <Button
              leftIcon={<SquaresPlusIcon className="h-4 w-4" />}
              variant="ghost"
              onClick={() => {
                setShowAddModal(!showAddModal);
                dispatch(toggleEdit());
              }}
            >
              Add
            </Button>
          )}

          <Button
            key={isEditing ? "edit" : "done"}
            variant={isEditing ? "solid" : "ghost"}
            colorScheme={isEditing ? "purple" : "gray"}
            onClick={() => dispatch(toggleEdit())}
            leftIcon={<Squares2X2Icon className="h-4 w-4" />}
          >
            {isEditing ? "Done" : "Edit"}
          </Button>
          <Button
            variant="ghost"
            leftIcon={<AdjustmentsVerticalIcon className="h-[17px] w-[17px]" />}
            onClick={() => setShowAudienceFilters(!showAudienceFilters)}
          >
            {showAudienceFilters
              ? "Hide audience filters"
              : "Show audience filters"}{" "}
            <Badge
              ml={2}
              bg="purple.500"
              color="white"
              px={1.5}
              py={1}
              rounded="md"
              size="xs"
            >
              {getFilterCount(audienceFilters)}
            </Badge>
          </Button>
        </div>
      </div>
      <div className="my-5">
        <AnimatePresence>
          {showAudienceFilters && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.1, ease: "easeInOut" }}
              className="mt-10 flex"
            >
              <div className="flex w-full justify-between">
                {isFetchingViews ||
                  (isFetchingAudience && isLoadingAudience && (
                    <Skeleton className="h-10 w-full rounded-md" />
                  ))}
                {audience?.isSavedAudience ? (
                  <div className="flex">
                    <div className="flex w-full items-center gap-x-2 rounded-md bg-gray-50 pl-3 text-sm">
                      <div>
                        <UserGroupIcon className="h-4 w-4 " />
                      </div>
                      <Tooltip
                        label="This is a saved audience"
                        hasArrow
                        placement="top"
                      >
                        <p className="w-full truncate text-sm font-medium">
                          {audience?.name}
                        </p>
                      </Tooltip>
                      <Tooltip label="Remove audience" hasArrow>
                        <div
                          className="cursor-pointer rounded-md p-2 hover:bg-gray-100"
                          onClick={onRemoveAudience}
                        >
                          <XMarkIcon className="h-4 w-4" />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  <>
                    <AudienceFilters
                      key={JSON.stringify(audienceFilters)}
                      audienceFilters={audienceFilters}
                      setAudienceFilters={setAudienceFilters}
                      view={userAppObject?.slug}
                      showClearFilters={false}
                    >
                      <AddAudienceMenu
                        isDisabled={getFilterCount(audienceFilters) > 0}
                        onAddAudience={onAddAudience}
                      />
                    </AudienceFilters>
                    <Tooltip
                      label={
                        isAudienceUpdated
                          ? "Update the audience"
                          : "Audience has not changed"
                      }
                      hasArrow
                    >
                      <Button
                        colorScheme="purple"
                        variant="ghost"
                        isDisabled={!isAudienceUpdated}
                        onClick={onSaveAudience}
                        isLoading={isLoadingAudience}
                      >
                        Save
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Flex my={3} />
      {isLoadingViews ? (
        <div className="flex flex-col gap-5">
          <div className="flex gap-5">
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
          </div>
          <div className="flex gap-5">
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
            <Skeleton
              borderRadius="lg"
              className="h-[315px] w-[350px] rounded"
            />
          </div>
        </div>
      ) : (
        <ViewContainer
          viewId={Number(viewId)}
          location={ViewLocation.Home}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          showSQLModal={showSQLModal}
          setShowSQLModal={setShowSQLModal}
        />
      )}
      <div className="pb-10" />
      {shouldShowGettingStartedBanner && <GettingStartedBanner />}
    </PageContainer>
  );
};
