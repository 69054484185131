import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  IView,
  useGetViewsQuery,
  useLazyGetViewQuery,
} from "core/models/views";
import {
  IViewInsight,
  useLazyGetViewInsightsQuery,
} from "core/models/viewInsights";
import { useCurrentApp } from "core/hooks/useCurrentApp";

const VIEWS_LOCAL_STORAGE_KEY = `views`;

export const MAX_INSIGHTS_PER_VIEW = 15;

export enum ViewLocation {
  Home = "home",
  Company = "company_profile",
}

export const useViews = ({
  location,
  viewId,
  pinned,
}: {
  location: ViewLocation;
  viewId?: number;
  pinned?: boolean;
}): {
  view?: IView;
  hasView: boolean;
  isLoadingView: boolean;
  views?: IView[];
  localViews?: IView[];
  isLoadingViews: boolean;
  viewInsights?: IViewInsight[];
  isLoadingViewInsights: boolean;
  reachedLimit: boolean;
  isFetchingViews: boolean;
} => {
  const { viewId: paramViewId } = useParams();
  const _viewId = viewId ? viewId : paramViewId;
  const currentApp = useCurrentApp();
  const appId = currentApp?.id as number;
  const {
    data: views,
    isLoading: isLoadingViews,
    isFetching: isFetchingViews,
  } = useGetViewsQuery({ appId, location, pinned });
  const [
    getView,
    { data: view, isLoading: isLoadingView, isFetching: isFetchingView, error },
  ] = useLazyGetViewQuery();
  const [
    getViewInsights,
    {
      data: viewInsights,
      isLoading: isLoadingViewInsights,
      isFetching: isFetchingViewInsights,
    },
  ] = useLazyGetViewInsightsQuery();

  useEffect(() => {
    if (_viewId && appId) {
      getView({ id: Number(_viewId), appId });
      getViewInsights({ viewId: Number(_viewId), appId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_viewId, appId]);

  const localViews = localStorage.getItem(
    `${VIEWS_LOCAL_STORAGE_KEY}-${appId}`,
  );
  const parsedLocalViews =
    (localViews && JSON.parse(localViews)) || ([] as IView[]);

  useEffect(() => {
    if (!views) return;
    if (parsedLocalViews?.length !== views?.views?.length) {
      localStorage.setItem(
        `${VIEWS_LOCAL_STORAGE_KEY}-${appId}`,
        JSON.stringify(
          views?.views?.map((v: IView) => ({ id: v.id, name: v.name })),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views?.views?.length]);

  const errorLoadingView = error as unknown as any;

  return {
    view,
    hasView: !errorLoadingView && errorLoadingView?.originalStatus !== 404,
    isLoadingView: isLoadingView || isFetchingView,
    views: views?.views,
    localViews: parsedLocalViews,
    isLoadingViews: isLoadingViews || isFetchingViews,
    isFetchingViews,
    viewInsights,
    isLoadingViewInsights: isLoadingViewInsights || isFetchingViewInsights,
    reachedLimit: viewInsights?.length === MAX_INSIGHTS_PER_VIEW,
  };
};
