import { cx } from "helpers/cx";
import { motion } from "framer-motion";
import { useNavigation } from "core/hooks/useNavigation";
import { ComponentDefaultProps, Container } from "@chakra-ui/react";
import { BookACallBanner } from "./Onboarding/BookACallBanner";

export const PageContainer: React.FC<
  {
    className?: string;
    maxW?: string;
    bgColor?: string;
    px?: string | number;
    mx?: string | number;
    shadow?: boolean;
    children: React.ReactNode;
  } & ComponentDefaultProps
> = ({
  children,
  maxW = "container.xl",
  bgColor = "bg-white",
  px = "60px",
  mx = "auto",
  shadow = true,
  className,
  ...props
}) => {
  const { navigation } = useNavigation();
  const navigationIsOpen = navigation.isOpen;

  return (
    <div
      data-testid="page-container"
      className={cx(
        "h-full min-h-[100vh] w-full min-w-[100vw] overflow-hidden bg-gray-50",
        className,
      )}
    >
      <div className="h-[99.9vh] p-2">
        <motion.div
          initial={{ marginLeft: navigationIsOpen ? "290px" : "40px" }}
          animate={{ marginLeft: navigationIsOpen ? "290px" : "40px" }}
          transition={{ duration: 0.2 }}
          className={cx(
            "h-full overflow-auto rounded-lg py-8",
            shadow && "shadow",
            bgColor && bgColor,
          )}
        >
          <Container px={px} maxW={maxW} minW="container.lg" mx={mx} {...props}>
            {children}
            <BookACallBanner />
          </Container>
        </motion.div>
      </div>
    </div>
  );
};
