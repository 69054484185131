import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ViewContainer } from "modules/Views/Container";
import { GroupContacts } from "modules/Contacts/GroupContacts";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { Header as CompanyHeader } from "modules/Company/Header";
import { EnrichmentCard as CompanyEnrichmentCard } from "modules/Company/EnrichmentCard";
import { ITrait } from "core/types/Trait";
import { IView } from "core/models/views";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { useRecentlyViewedPeople } from "core/hooks/useRecentlyViewedPeople";
import { useNavigation } from "core/hooks/useNavigation";
import { useCompanyColumns } from "core/hooks/useCompanyColumns";
import { useCompany } from "core/hooks/useCompany";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from "core/design-system/components/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { TRAIT_LEVEL } from "core/constants/traits";
import { baseUrl } from "core/constants/paywall";
import WarningBanner from "core/components/WarningBanner";
import TraitsContainer from "core/components/Traits/Container";
import { PaywallModal } from "core/components/Paywall/Modal";
import { PageContainer } from "core/components/PageContainer";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { Squares2X2Icon, SquaresPlusIcon } from "@heroicons/react/24/outline";
import { Box, Button, Flex, Skeleton, TabPanels } from "@chakra-ui/react";
import useFlag from "core/hooks/useFlag";
import { SQL_EDITOR } from "core/constants/features";
import { AddMenu } from "modules/Views/AddMenu";

function Company() {
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const { save } = useRecentlyViewedPeople();
  const groupId = decodeURIComponent(encodedGroupId);
  const { company, isCompanyLoading, companyError } = useCompany({
    appId: Number(appId),
    id: groupId,
  });
  const { companyAppObject } = useAppObjects();
  const [view, setView] = useState<IView | null>();
  const { views } = useViews({
    location: ViewLocation.Company,
    pinned: false,
  });
  const [showPaywall, setShowPaywall] = useState<boolean>(false);
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const redirectUrl = `${baseUrl}/a/${appId}/home`;
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const { columns } = useCompanyColumns();
  const { navigation, toggleSidebar } = useNavigation();
  const hasSQLEditor = useFlag(SQL_EDITOR);

  company &&
    companyAppObject &&
    save(appId, company, companyAppObject.objectType);

  useEffect(() => {
    if (views && views?.length > 0) setView(views[0]);
  }, [views]);

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!groupId) return <Navigate to={`/a/${appId}/home`} />;

  const pages = [
    {
      name: companyAppObject?.pluralName ?? "Companies",
      path: `/a/${appId}/people`,
      current: false,
    },
    {
      name: company?.name || "Unknown",
      path: `/a/${appId}/company/${encodedGroupId}`,
      current: true,
    },
  ];

  return (
    <PageContainer
      className="h-full bg-gray-25"
      bgColor={isEditing ? "bg-gray-50" : "bg-white"}
      px={columns === 3 ? "0px" : "20px"}
      minW="2xl"
      shadow={!isEditing}
    >
      <div className="mb-6">
        <Breadcrumbs pages={pages} />
      </div>
      {companyError && (
        <WarningBanner title="This company does not exist" description="" />
      )}

      <Flex w="full" direction="row" gridGap={5} minW="100%" maxW="full">
        <Box minW="340px" w="30%" opacity={isEditing ? 0.5 : 1}>
          <div className="flex flex-col gap-5">
            {isCompanyLoading ? (
              <Skeleton borderRadius="lg" height="264px" w="100%" />
            ) : (
              <CompanyHeader />
            )}
            {isCompanyLoading ? (
              <Skeleton borderRadius="lg" height="300px" w="100%" />
            ) : (
              <TraitsContainer
                id={groupId}
                traitLevel={TRAIT_LEVEL.COMPANY}
                traitKeyValues={company?.traits as ITrait[]}
                showError={false}
                allowComputedTrait={false}
              />
            )}
            {isCompanyLoading ? (
              <Skeleton borderRadius="lg" height="300px" w="100%" />
            ) : (
              <>{company && <CompanyEnrichmentCard />}</>
            )}
          </div>
        </Box>
        <div className="max-w-full">
          {isCompanyLoading ? (
            <>
              <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
              <Skeleton borderRadius="lg" height="300px" w="100%" mb={4} />
              <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
            </>
          ) : (
            <StyledTabs isLazy defaultIndex={0} w="980px" maxW="full">
              <StyledTabList
                boxShadow="sm"
                w="100%"
                opacity={isEditing ? 0.5 : 1}
              >
                <StyledTab>Overview</StyledTab>
                <StyledTab>Feed</StyledTab>
                <StyledTab>Users</StyledTab>
              </StyledTabList>
              <TabPanels maxW="full">
                <StyledTabPanel
                  minW={columns === 3 ? "980px" : "790px"}
                  w="100%"
                >
                  <div className="mb-4 flex items-center justify-between">
                    <p className="text-lg font-semibold">Overview</p>
                    <Flex justifyContent="flex-end" w="100%" gap={1}>
                      {hasSQLEditor ? (
                        <AddMenu
                          onAddInsightClick={() => {
                            setShowAddModal(!showAddModal);
                            dispatch(toggleEdit());
                          }}
                          onAddSQLClick={() => {
                            setShowSQLModal(!showSQLModal);
                            dispatch(toggleEdit());
                          }}
                        />
                      ) : (
                        <Button
                          className="company-profile-add-insight-button"
                          variant="ghost"
                          onClick={() => {
                            dispatch(toggleEdit());
                            setShowAddModal(!showAddModal);
                          }}
                          leftIcon={<SquaresPlusIcon className="h-4 w-4" />}
                        >
                          Add
                        </Button>
                      )}
                      <Button
                        key={isEditing ? "edit" : "done"}
                        variant={isEditing ? "solid" : "ghost"}
                        colorScheme={isEditing ? "purple" : "gray"}
                        onClick={() => {
                          dispatch(toggleEdit());
                        }}
                        leftIcon={<Squares2X2Icon className="h-4 w-4" />}
                      >
                        {isEditing ? "Done" : "Edit"}
                      </Button>
                    </Flex>
                  </div>
                  <Box pb={10} w="full">
                    {view && (
                      <ViewContainer
                        location={ViewLocation.Company}
                        viewId={Number(view.id)}
                        columns={columns}
                        width={columns === 3 ? "315px" : "385px"}
                        paywallInsights
                        showAddModal={showAddModal}
                        setShowAddModal={setShowAddModal}
                        showSQLModal={showSQLModal}
                        setShowSQLModal={setShowSQLModal}
                      />
                    )}
                  </Box>
                </StyledTabPanel>
                <StyledTabPanel
                  minW={columns === 3 ? "980px" : "790px"}
                  w="100%"
                >
                  {company && (
                    <ContactEventLog
                      appId={Number(appId)}
                      groupId={groupId}
                      groupType={GroupType.Company}
                    />
                  )}
                </StyledTabPanel>
                <StyledTabPanel
                  minW={columns === 3 ? "980px" : "790px"}
                  w="100%"
                >
                  <GroupContacts />
                </StyledTabPanel>
              </TabPanels>
            </StyledTabs>
          )}
          {showPaywall && (
            <PaywallModal redirectUrl={redirectUrl}>
              {(paywallModal) => (
                <paywallModal.Modal
                  isOpen
                  onClose={() => setShowPaywall(false)}
                />
              )}
            </PaywallModal>
          )}
        </div>
      </Flex>
    </PageContainer>
  );
}

export default Company;
