import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { cx } from "helpers/cx";
import {
  ITemplateConfigSetup,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { useReportSetup } from "core/hooks/useReportSetup";
import { TwEditable } from "core/design-system/components/TwEditable";
import { MULTI_EVENT_TYPE, OR } from "core/constants/report-setup";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Bars2Icon } from "@heroicons/react/24/outline";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Button } from "@chakra-ui/react";
import { MultiEventPicker } from "./Event";

function generateStepId() {
  return Math.random().toString(36).slice(2);
}

function buildDynamicSections(
  report: IReport,
  funnelConfigSteps: string[],
  dynamicSetupSection?: ITemplateConfigSetupSection,
): ITemplateConfigSetupSection[] {
  return funnelConfigSteps
    .map((configKey, index) => {
      return {
        ...dynamicSetupSection,
        id: String(index),
        configKey,
        type: MULTI_EVENT_TYPE,
        title: report.config["name"][configKey],
      };
    })
    .sort((a, b) => {
      if (!report.config.order) return 0;

      const aIndex = report.config.order.indexOf(a.configKey);
      const bIndex = report.config.order.indexOf(b.configKey);
      return aIndex - bIndex;
    });
}

const SortableMultiEventPicker: React.FC<{
  section: ITemplateConfigSetupSection;
  setup: ITemplateConfigSetup;
  report: IReport;
  handleNameChange: (name: string, configKey: string) => void;
  handleRemove: (configKey: string) => void;
  onUpdateReport?: () => void;
}> = ({
  section,
  setup,
  report,
  handleNameChange,
  handleRemove,
  onUpdateReport,
}) => {
  const {
    setNodeRef,
    attributes,
    transform,
    transition,
    listeners,
    isDragging,
  } = useSortable({
    id: section.id,
  });

  return (
    <li
      {...attributes}
      ref={setNodeRef}
      className={cx(
        "border-1 list-none rounded-md border-gray-200 p-4 shadow-sm",
        isDragging ? "z-overlay bg-gray-200" : "bg-white",
      )}
      style={{
        position: "relative",
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <div className="flex flex-col gap-2" key={section.id}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {section.ordering && (
              <button {...listeners}>
                <Bars2Icon className="h-3 w-3 text-gray-500" cursor="grab" />
              </button>
            )}
            <TwEditable
              data-testid="setup-accordion-title-editable"
              key={section?.configKey}
              fontSize="text-sm"
              textColor="text-gray-600"
              fontStyle="font-medium"
              defaultValue={
                report?.config?.name?.[section?.configKey]
                  ? report?.config?.name[section?.configKey]
                  : "Untitled"
              }
              onSubmit={(name: string) =>
                handleNameChange(name, section?.configKey)
              }
            />
          </div>
          {section.isRemovable && (
            <Button
              onClick={() => handleRemove(section.configKey)}
              variant="ghost"
              className="group"
            >
              <TrashIcon
                className="h-3 w-3 text-gray-500 group-hover:text-gray-900"
                cursor="pointer"
              />
            </Button>
          )}
        </div>
        <MultiEventPicker
          setup={setup}
          section={section}
          onUpdateReport={onUpdateReport}
        />
      </div>
    </li>
  );
};

export const DynamicMultiEventPicker: React.FC<{
  section: ITemplateConfigSetupSection;
  setup: ITemplateConfigSetup;
  onUpdateReport?: () => void;
}> = ({ setup, section, onUpdateReport }) => {
  const {
    currentReport,
    addFunnelStep,
    removeFunnelStep,
    updateFunnelOrder,
    updateSectionName,
  } = useReportSetup();
  setup.eventOperator = [OR]; // Override the current THEN join operator
  const funnelConfigSteps = Object.keys(currentReport.config).filter(
    (configKey) => configKey.includes(section?.configKey),
  );
  const dynamicSections = buildDynamicSections(
    currentReport as any as IReport,
    funnelConfigSteps,
    section,
  );
  const funnelOrder = currentReport?.config?.order;
  const [sections, setSections] =
    useState<ITemplateConfigSetupSection[]>(dynamicSections);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
  );

  function onAddStep(index: string) {
    addFunnelStep(`${section?.configKey}${index}`);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = sections.findIndex(
        (section) => section.id === active.id,
      );
      const newIndex = sections.findIndex((section) => section.id === over?.id);

      const newSections = arrayMove(sections, oldIndex, newIndex);
      const newFunnelOrder = newSections.map((section) => section.configKey);

      if (JSON.stringify(newFunnelOrder) !== JSON.stringify(funnelOrder)) {
        updateFunnelOrder(newFunnelOrder);
        setSections(newSections);
        onUpdateReport && onUpdateReport();
      }
    }
  }

  const handleSectionNameChange = debounce(
    (name: string, configKey: string) => updateSectionName({ name, configKey }),
    500,
  );

  useEffect(() => {
    if (sections.length === funnelConfigSteps.length) return;
    const newDynamicSections = buildDynamicSections(
      currentReport as any as IReport,
      funnelConfigSteps,
      section,
    );
    setSections(newDynamicSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelConfigSteps, currentReport.config]);

  return (
    <div className="flex w-full flex-col gap-4">
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext
          items={sections.map((section) => section.id)}
          strategy={verticalListSortingStrategy}
        >
          <ul className="flex flex-col gap-4">
            {sections.map((section: ITemplateConfigSetupSection) => {
              return (
                <SortableMultiEventPicker
                  key={section.id}
                  section={section}
                  setup={setup}
                  report={currentReport as any as IReport}
                  handleNameChange={handleSectionNameChange}
                  handleRemove={removeFunnelStep}
                  onUpdateReport={onUpdateReport}
                />
              );
            })}
          </ul>
        </SortableContext>
      </DndContext>
      {section.limit && section.limit > sections.length && (
        <div className="flex justify-end">
          <Button
            data-testid="funnel-setup-sections-add-step-button"
            onClick={() =>
              onAddStep(
                !section?.isRemovable
                  ? String(sections.length + 1)
                  : generateStepId(),
              )
            }
            className="group"
          >
            <div className="flex items-center gap-1">
              <PlusIcon className="h-4 w-4" />
              <span>Add {section?.configKey}</span>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};
