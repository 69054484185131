import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GraphType, Size, State } from "core/types/ViewInsight";
import {
  IExecuteResponse,
  useLazyExecuteQuery,
  useLazyPromptQuery,
  useUpdateMutation,
} from "core/models/sql";
import {
  COLOR_NAME_TO_PALETTE,
  COLOR_NAMES,
  COLOR_PALETTES,
  GRID_WIDTH,
  GRID_WIDTH_TO_SIZE,
} from "./useEditViewInsight";
import { IQuery } from "../types/Query";
import {
  IViewInsight,
  useLazyGetViewInsightsQuery,
} from "../models/viewInsights";

export const useSqlEditor = ({
  viewInsight,
  execute,
}: {
  viewInsight: IViewInsight;
  execute?: boolean;
}) => {
  const { appId, groupId } = useParams();
  const [getViewInsights] = useLazyGetViewInsightsQuery();

  const [query, setQuery] = useState(
    (viewInsight.query as IQuery).query as string,
  );
  const [colorPalette, setColorPalette] = useState(
    COLOR_NAME_TO_PALETTE[viewInsight.color],
  );
  const [size, setSize] = useState<Size>(
    GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] as Size,
  );
  const [title, setTitle] = useState(viewInsight.title || "Untitled");
  const [xAxis, setXAxis] = useState<string>(
    (viewInsight.query as IQuery).graphXAxis,
  );
  const [yAxis, setYAxis] = useState<string>(
    (viewInsight.query as IQuery).graphYAxis,
  );
  const [graphType, setGraphType] = useState<GraphType>(viewInsight.graphType);
  const [prompt, setPrompt] = useState("");
  const [suggestion, setSuggestion] = useState<string | null>(null);
  const [data, setData] = useState<IExecuteResponse | null>(null);
  const [update] = useUpdateMutation();

  const [
    runPrompt,
    {
      data: suggestionData,
      isLoading: isPromptLoading,
      isFetching: isPromptFetching,
    },
  ] = useLazyPromptQuery();
  const [
    executeQuery,
    {
      data: executeData,
      isLoading: isExecuteLoading,
      isFetching: isExecuteFetching,
    },
  ] = useLazyExecuteQuery();

  useEffect(() => {
    if (execute) executeQuery({ appId: Number(appId), query, groupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    update({
      appId: Number(appId),
      viewInsightId: viewInsight.id,
      title,
      graphType,
      color: COLOR_NAMES[COLOR_PALETTES.indexOf(colorPalette)],
      gridWidth: GRID_WIDTH[size || Size.Small],
      graphXAxis: xAxis,
      graphYAxis: yAxis,
      query,
      state: viewInsight.state,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, title, graphType, colorPalette, size, query]);

  useEffect(() => {
    if (suggestionData) {
      setSuggestion(suggestionData.query);
    }
  }, [suggestionData]);

  useEffect(() => {
    if (executeData) {
      setData(executeData);
      setXAxis(executeData?.result?.columns?.[0]?.name);
      setYAxis(executeData?.result?.columns?.[1]?.name);
    }
  }, [executeData]);

  function onPrompt() {
    runPrompt({ appId: Number(appId), prompt, query });
  }

  function onExecute() {
    executeQuery({ appId: Number(appId), query, groupId });
  }

  function onApply() {
    if (suggestion) {
      setQuery(suggestion);
      setSuggestion(null);
      setPrompt("");
    }
  }

  function onReject() {
    setSuggestion(null);
    setPrompt("");
  }

  function onCreate() {
    update({
      appId: Number(appId),
      viewInsightId: viewInsight.id,
      title,
      graphType,
      color: COLOR_NAMES[COLOR_PALETTES.indexOf(colorPalette)],
      gridWidth: GRID_WIDTH[size || Size.Small],
      graphXAxis: xAxis,
      graphYAxis: yAxis,
      query,
      state: State.Persisted,
    });
  }

  function onInvalidateInsights() {
    getViewInsights({ appId: viewInsight.appId, viewId: viewInsight.viewId });
  }

  return {
    prompt,
    setPrompt,
    query,
    setQuery,
    suggestion,
    setSuggestion,
    data,
    size,
    setSize,
    graphType,
    setGraphType,
    colorPalette,
    setColorPalette,
    xAxis,
    setXAxis,
    yAxis,
    setYAxis,
    title,
    setTitle,
    isPromptFetching,
    isExecuteFetching,
    isPromptLoading,
    isExecuteLoading,
    executeQuery,
    onPrompt,
    onExecute,
    onApply,
    onReject,
    onCreate,
    onInvalidateInsights,
    hasError: data?.error,
  };
};
