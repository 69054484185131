import { useState } from "react";
import { Size } from "core/types/ViewInsight";
import { ExplorationLegend } from "core/modules/reports/report-types/Explorer/ExplorationLegend";
import { useChartData } from "core/modules/reports/report-types/Explorer/ExplorationContainer";
import TickText from "core/design-system/charts/TickText";
import {
  CommonTooltip,
  CommonXAxis,
  CommonYAxis,
} from "core/design-system/charts/Common";
import {
  BarChart,
  BarItem,
  BarRectangle,
} from "core/design-system/charts/BarChart";
import { BarCustomTooltip } from "core/modules/reports/report-types/Explorer/BarCustomTooltip";

interface ISerie {
  color: string;
  data: { x: string; y: number; eventProperties?: string[] }[];
  max: number;
  mean: number;
  min: number;
  name: string;
  p90: number;
  total: number;
  uuid: string;
}

interface ISimpleStackedLineChart {
  data: ISerie[];
  size: Size;
  showYAxis: boolean;
}

export const simplifyXAxisTicks = (
  data: { data: { [key: string]: object }[] }[],
  dataKey: string,
) => {
  const firstTick = data && data.length && data[0].data[0][dataKey];
  const lastTick =
    data && data.length && data[0].data[data[0].data.length - 1][dataKey];
  return {
    firstTick,
    lastTick,
  };
};

export const getAllXAxisTicks = (
  data: { data: { [key: string]: object }[] }[],
  dataKey: string,
): string[] => {
  if (
    !data ||
    data.length === 0 ||
    !data[0].data ||
    data[0].data.length === 0
  ) {
    return [];
  }

  // Assuming all series have the same x-axis values
  return data[0].data.map((item) => String(item[dataKey]));
};

export const SimpleStackedBarChart: React.FC<ISimpleStackedLineChart> = ({
  data,
  size,
  showYAxis,
}) => {
  const { series } = useChartData({ data });
  const [hoveringIndex, setHoveringIndex] = useState<string | undefined>(
    undefined,
  );

  if (!series || series.length < 1) {
    return null;
  }

  const transformedData = series[0].data.map((item) => {
    const newItem: { [key: string]: string | number } = { x: item.x };
    series.forEach((serie) => {
      const matchingDataPoint = serie.data.find((d) => d.x === item.x);
      newItem[serie.name] = matchingDataPoint ? matchingDataPoint.y : 0;
    });
    return newItem;
  });

  const { firstTick, lastTick } = simplifyXAxisTicks(series, "x");
  const allTicks = getAllXAxisTicks(series, "x");

  return (
    <div className="flex h-full w-full flex-col justify-between">
      <div className="flex h-5/6 w-full flex-col">
        <BarChart
          data={transformedData}
          margin={{ top: 0, left: -30, right: 0, bottom: 0 }}
        >
          {/* @ts-ignore */}
          <CommonTooltip content={<BarCustomTooltip />} />
          {/* @ts-ignore */}
          <CommonXAxis
            type="category"
            dataKey="x"
            tickCount={2}
            interval="preserveStartEnd"
            ticks={
              [Size.Medium, Size.Large].includes(size)
                ? allTicks
                : [firstTick, lastTick]
            }
          />
          {/* @ts-ignore */}
          <CommonYAxis
            // @ts-ignore
            tick={({ x, ...props }) =>
              showYAxis && (
                <TickText x={x - 5} {...props}>
                  {props.payload.value}
                </TickText>
              )
            }
          />
          {series.map((serie, idx) => (
            <BarItem
              key={idx}
              dataKey={serie.name}
              fill={serie.color}
              shape={
                <BarRectangle
                  radius={
                    idx !== series.length - 1 ? [0, 0, 0, 0] : [5, 5, 0, 0]
                  }
                />
              }
              stackId="a"
              opacity={
                hoveringIndex !== undefined
                  ? String(serie.uuid) === String(hoveringIndex)
                    ? 1
                    : 0.2
                  : 1
              }
            />
          ))}
        </BarChart>
      </div>
      <div className="max-w-full overflow-x-scroll">
        <ExplorationLegend
          series={series}
          size={size || Size.Small}
          onHover={setHoveringIndex}
        />
      </div>
    </div>
  );
};
