import { ITemplateConfig } from "core/types/TemplateConfig";
import { IReport } from "core/types/Report";
import { IViewInsight } from "core/models/viewInsights";
import { GraphType, Interval, Measure, Size } from "core/types/ViewInsight";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { SetupContainer } from "./Setup/Container";
import { PreviewContainer } from "./Preview/Container";
import { IAppObject } from "core/models/appObjects";

export const Sizes = {
  [Size.Small]: { width: "315px", height: "315px" },
  [Size.Medium]: { width: "630px", height: "315px" },
  [Size.Large]: { width: "945px", height: "315px" },
};

export const LayoutContainer: React.FC<{
  viewInsight: IViewInsight | null;
  report: IReport;
  templateConfig: ITemplateConfig;
  measure: Measure;
  appObject: IAppObject;
  graphType: GraphType;
  size: Size;
  colorPalette: IColorPalette;
  colors: IColorPalette[];
  title: string;
  interval: Interval;
  isAddingNewInsight?: boolean;
  useViewAudience: boolean;
  setMeasure: (measure: Measure) => void;
  setGraphType: (graphType: GraphType) => void;
  setSize: (size: Size) => void;
  setColorPalette: (colorPalette: IColorPalette) => void;
  setTitle: (title: string) => void;
  setInterval: (interval: Interval) => void;
  setAppObject: (appObject: IAppObject) => void;
  setUseViewAudience: (useViewAudience: boolean) => void;
  onUpdateReport: () => void;
}> = ({
  viewInsight,
  report,
  templateConfig,
  measure,
  graphType,
  size,
  colorPalette,
  colors,
  title,
  interval,
  isAddingNewInsight,
  appObject,
  useViewAudience,
  setMeasure,
  setGraphType,
  setSize,
  setColorPalette,
  setTitle,
  setInterval,
  setAppObject,
  setUseViewAudience,
  onUpdateReport,
}) => {
  return (
    <div className="grid h-full grid-cols-6 items-center gap-x-5">
      <div className="col-span-4 h-full">
        {viewInsight && (
          <PreviewContainer
            viewInsight={viewInsight}
            report={report}
            measure={measure}
            size={size}
            setSize={setSize}
            width={Sizes[size].width}
            height={Sizes[size].height}
            graphType={graphType}
            setGraphType={setGraphType}
            colorPalette={colorPalette}
            setColorPalette={setColorPalette}
            colors={colors}
            title={title}
            setTitle={setTitle}
            interval={interval}
            setInterval={setInterval}
            appObject={appObject}
            setAppObject={setAppObject}
            isAddingNewInsight={isAddingNewInsight}
          />
        )}
      </div>
      <div className="col-span-2 h-full overflow-y-auto">
        {viewInsight && report && (
          <SetupContainer
            report={report}
            templateConfig={templateConfig}
            measure={measure}
            setMeasure={setMeasure}
            appObject={appObject}
            setAppObject={setAppObject}
            isAddingNewInsight={isAddingNewInsight}
            useViewAudience={useViewAudience}
            setUseViewAudience={setUseViewAudience}
            onUpdateReport={onUpdateReport}
          />
        )}
      </div>
    </div>
  );
};
