import { simplifyXAxisTicks } from "helpers/xAxis";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import { CommonXAxis } from "core/design-system/charts/Common";
import {
  BarChart,
  BarItem,
  BarRectangle,
  BarTooltip,
} from "core/design-system/charts/BarChart";
import { Size } from "core/types/ViewInsight";

interface ISimpleBarChartProps {
  data: any[];
  xDataKey?: string;
  yDataKey?: string;
  margin?: { right: number; top: number; bottom: number; left: number };
  color?: IColorPalette;
  size?: Size;
}

const CustomTooltip: React.FC<any> = ({
  active,
  payload,
  label,
  xDataKey,
  yDataKey,
  size,
  ...props
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg bg-gray-900 p-2">
        <p className="text-xs text-gray-600">{label}</p>
        <p className="text-sm text-white">{payload[0].payload[yDataKey]}</p>
      </div>
    );
  }
  return null;
};

export const SimpleBarChart: React.FC<ISimpleBarChartProps> = ({
  data,
  xDataKey = "x",
  yDataKey = "y",
  margin = { right: 0, top: 30, bottom: 0, left: 20 },
  color = colors.purple,
  size = Size.Small,
}) => {
  const { firstTick, lastTick } = simplifyXAxisTicks(data, "x");
  const showTicks = [Size.Medium, Size.Large].includes(size);
  const allTicks = data?.map((item) => item[xDataKey]);

  return (
    <BarChart showYAxis={false} showXAxis={false} margin={margin} data={data}>
      {/* @ts-ignore */}
      <BarTooltip
        content={<CustomTooltip xDataKey={xDataKey} yDataKey={yDataKey} />}
      />
      <BarItem
        barSize={50}
        fill={color[300]}
        dataKey={yDataKey}
        background={{ fill: "transparent" }}
        minPointSize={2}
        shape={<BarRectangle radius={[3, 3, 3, 3]} />}
      />
      {/* @ts-ignore */}
      <CommonXAxis
        ticks={showTicks ? allTicks : [firstTick, lastTick]}
        dataKey={xDataKey}
      />
    </BarChart>
  );
};
