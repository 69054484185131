import { SQLEditor } from "./SQLEditor";
import { usePreviewQuery } from "core/models/sql";
import { useParams } from "react-router-dom";

export const SQLEditorContainer: React.FC<{
  viewId: number;
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose, viewId }) => {
  const { appId } = useParams();
  const { data: viewInsight, isLoading } = usePreviewQuery({
    appId: Number(appId),
    viewId,
  });

  if (isLoading || !viewInsight) return <></>;

  return (
    <SQLEditor
      viewInsight={viewInsight}
      viewId={viewId}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
