import { ViewInsightContainer } from "modules/ViewInsight/ViewInsightContainer";
import { AIViewInsightContainer } from "modules/ViewInsight/AIViewInsightContainer";
import { IQuery } from "core/types/Query";
import { IView } from "core/models/views";
import { IViewInsight } from "core/models/viewInsights";
import { ViewLocation } from "core/hooks/useViews";
import { REPORT_CONFIGS } from "core/constants/report-configurations";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

export interface IViewInsightContainer {
  location: ViewLocation;
  viewInsight: IViewInsight;
  view?: IView;
  query?: IQuery;
  isEditing?: boolean;
  isAdding?: boolean;
  isAdded?: boolean;
  viewId?: number;
  isBlurred?: boolean;
  isAI?: boolean;
  onSetViewInsight?: (viewInsight: IViewInsight) => void;
}

export const ViewInsight: React.FC<IViewInsightContainer> = ({
  location,
  viewInsight,
  view,
  query,
  isEditing,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  isAI,
  onSetViewInsight,
}) => {
  if (isAI)
    return (
      <AIViewInsightContainer
        location={location}
        viewInsight={viewInsight}
        isEditing={isEditing}
        isAdding={isAdding}
        isAdded={isAdded}
        viewId={viewId}
        isBlurred={isBlurred}
        isAI={isAI}
        query={query}
      />
    );

  return (
    <ViewInsightContainer
      location={location}
      viewInsight={viewInsight}
      isEditing={isEditing}
      isAdding={isAdding}
      isAdded={isAdded}
      viewId={viewId}
      isBlurred={isBlurred}
      onSetViewInsight={onSetViewInsight}
      view={view}
    />
  );
};
