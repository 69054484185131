import React, { SyntheticEvent, useState } from "react";
import { cx } from "helpers/cx";
import { Size } from "core/types/ViewInsight";
import { IGroupedTimerangeOption } from "core/types/TimerangeOption";
import { IHightlight } from "core/types/TemplateConfig";
import { TimerangePicker } from "core/modules/reports/Timerange/TimerangePicker";
import {
  IViewInsight,
  useDeleteViewInsightMutation,
  useDuplicateViewInsightMutation,
} from "core/models/viewInsights";
import { useViewInsightTimerange } from "core/hooks/useViewInsightTimerange";
import { useToast } from "core/hooks/useToast";
import { useNavigateToReport } from "core/hooks/useNavigateToReport";
import { useInsightTitle } from "core/hooks/useInsightTitle";
import { GRID_WIDTH_TO_SIZE } from "core/hooks/useEditViewInsight";
import { IColorPalette } from "core/design-system/constants/theme/colors";
import { ABSOLUTE } from "core/constants/timerange";
import { HighlightCard } from "core/components/ViewInsight";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { MoonIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  CalendarIcon,
  CodeBracketSquareIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { EllipsisVerticalIcon } from "@heroicons/react/16/solid";
import {
  Button,
  Center,
  ComponentDefaultProps,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon, CheckCircleIcon, LockIcon } from "@chakra-ui/icons";
import { SQLEditor } from "./Modal/SQLEditor";
import { InsightBuilder } from "./Modal/InsightBuilder";
import { FullInsightModal } from "./FullInsightModal";

interface IViewInsightCard extends ComponentDefaultProps {
  viewInsight: IViewInsight;
  isPaywalled: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  showHighlightDescription: boolean;
  reachedLimit: boolean;
  description: string;
  title: string;
  timerange: string;
  hasTimerangePicker?: boolean;
  dateRangeOptions?: IGroupedTimerangeOption[];
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onDelete: (e: SyntheticEvent) => void;
  onAdd: (e: SyntheticEvent) => void;
  // optional
  highlight?: IHightlight;
  isAdding?: boolean;
  isAdded?: boolean;
  isBlurred?: boolean;
  isEditing?: boolean;
  isAI?: boolean;
  groupId?: string;
  color?: IColorPalette;
}

export const ViewInsightCard: React.FC<IViewInsightCard> = ({
  viewInsight,
  isEditing,
  isAI,
  isAdding,
  isAdded,
  isBlurred,
  isPaywalled,
  isEmpty,
  groupId,
  highlight,
  description,
  showHighlightDescription,
  title,
  isLoading,
  timerange,
  hasTimerangePicker,
  dateRangeOptions,
  reachedLimit,
  onMouseEnter,
  onMouseLeave,
  onDelete,
  onAdd,
  color,
  children,
  ...props
}) => {
  const toast = useToast();
  const pluralName = viewInsight?.appObject?.pluralName;
  const level = viewInsight?.appObject?.slug;
  const insightTitle = useInsightTitle({ title, name: pluralName });
  const insightDescription = useInsightTitle({
    title: description,
    name: pluralName,
  });

  const [duplicate] = useDuplicateViewInsightMutation();
  const [deleteViewInsight] = useDeleteViewInsightMutation();

  const {
    label,
    timerangeType,
    relativeTimerange,
    customTimerange,
    readableCustomTimerange,
    setCustomTimerange,
    saveTimerange,
  } = useViewInsightTimerange(viewInsight);
  const [isBuilderOpen, setIsBuilderOpen] = useState(false);
  const [isFullModeOpen, setIsFullModeOpen] = useState(false);
  const { navigateToReport } = useNavigateToReport({ viewInsight });

  return (
    <>
      <HighlightCard>
        {(hc) => (
          <hc.Container
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={(e: SyntheticEvent) => {
              if (isEditing && viewInsight.measure) {
                setIsBuilderOpen(true);
                return;
              }

              if (!isAdding && !isEditing && !isPaywalled && !isBlurred) {
                setIsFullModeOpen(true);
                return;
              }

              if (isAdding && reachedLimit && !isAdded) {
                return toast({
                  title: "Limit reached",
                  description:
                    "You have reached the limit of insights you can pin on this view.",
                  status: "error",
                });
              } else if (isAdding && !isAdded) {
                return onAdd(e);
              } else if (isAdding && isAdded) {
                return onDelete(e);
              }
              navigateToReport({
                groupId: groupId,
                level: level,
                skipNavigate: isBlurred,
              });
            }}
            data-testid="view-insight-card"
            {...props}
          >
            <hc.Header>
              <Flex w="full" justifyContent="space-between" position="relative">
                <Flex w="full">
                  <div className="flex w-full min-w-[180px]">
                    {!Boolean(groupId) && (
                      <Tooltip
                        label={
                          groupId
                            ? `Uses ${viewInsight.appObject?.singularName}'s users`
                            : viewInsight.useViewAudience
                              ? "Uses dashboard's audience filters"
                              : "Uses insight's audience filters"
                        }
                        placement="top"
                        hasArrow
                      >
                        <div
                          className={cx(
                            "relative right-[25px] top-[3px] h-[15px] w-1 rounded-full",
                            viewInsight.useViewAudience
                              ? "bg-purple-500"
                              : "bg-gray-400",
                          )}
                        />
                      </Tooltip>
                    )}
                    <hc.Title
                      title={insightTitle}
                      description={isAI ? "" : insightDescription}
                      highlight={highlight}
                      shouldBePaywalled={isBlurred || isPaywalled}
                      showHighlightDescription={showHighlightDescription}
                      isLoading={isLoading}
                      icon={
                        isAI ? (
                          <Tooltip hasArrow label="SQL insight">
                            <CodeBracketSquareIcon className="h-4 text-gray-500" />
                          </Tooltip>
                        ) : null
                      }
                      color={color}
                    >
                      <div className="relative -top-1 flex w-full items-center justify-between">
                        <div className="flex items-center">
                          {!(isPaywalled || isBlurred) &&
                            !isEditing &&
                            Boolean(viewInsight.measure) && (
                              <Tooltip
                                hasArrow
                                label="Edit insight"
                                placement="top"
                              >
                                <Button
                                  colorScheme="gray"
                                  variant="ghost"
                                  size="xs"
                                  top="1px"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setIsBuilderOpen(true);
                                  }}
                                >
                                  <PencilSquareIcon className="h-4 w-4 text-gray-500" />
                                  {GRID_WIDTH_TO_SIZE[viewInsight.gridWidth] !==
                                  Size.Small ? (
                                    <p className="ml-1 text-xs text-gray-500">
                                      Edit
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </Button>
                              </Tooltip>
                            )}

                          {isEditing && (
                            <Tooltip
                              hasArrow
                              label="Remove insight"
                              placement="top"
                            >
                              <Button
                                colorScheme="gray"
                                variant="ghost"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDelete(event);
                                }}
                              >
                                <TrashIcon className="h-4 w-4 text-gray-700" />
                              </Button>
                            </Tooltip>
                          )}
                          {isAdding && !isAdded && !isPaywalled && (
                            <Tooltip
                              label={
                                reachedLimit &&
                                "You have reached the limit of the number of insights you can pin"
                              }
                              shouldWrapChildren
                              hasArrow
                            >
                              <Button
                                colorScheme="purple"
                                variant="ghost"
                                isDisabled={reachedLimit}
                              >
                                <AddIcon />
                              </Button>
                            </Tooltip>
                          )}
                          {isAdding && isAdded && !isPaywalled && (
                            <Tooltip
                              label="This insight has already been pinned"
                              hasArrow
                            >
                              <CheckCircleIcon
                                h="18px"
                                w="18px"
                                color="purple.500"
                              />
                            </Tooltip>
                          )}
                        </div>
                        {!isPaywalled && timerange && !hasTimerangePicker && (
                          <Flex color="gray.500" align="center" gridGap={1}>
                            <CalendarIcon className="h-4" />
                            <Text fontWeight="medium" fontSize="xs">
                              {timerange}
                            </Text>
                          </Flex>
                        )}
                        {!isPaywalled &&
                          !isAdding &&
                          !isEditing &&
                          hasTimerangePicker && (
                            <TimerangePicker
                              label={label}
                              options={dateRangeOptions || []}
                              timerangeType={timerangeType}
                              relativeTimerange={relativeTimerange}
                              customTimerange={customTimerange}
                              setCustomTimerange={setCustomTimerange}
                              saveTimerange={saveTimerange}
                              size="small"
                              tooltipText={
                                timerangeType === ABSOLUTE
                                  ? readableCustomTimerange
                                  : undefined
                              }
                              footerText="Insights are in UTC time"
                            />
                          )}
                        {!isEditing && !isAdding && (
                          <Menu>
                            <MenuButton
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              as={Button}
                              variant="ghost"
                              size="xs"
                            >
                              <EllipsisVerticalIcon className="h-3 w-3 text-gray-500" />
                            </MenuButton>
                            <MenuList minW="100px">
                              <MenuItem
                                fontSize="xs"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  duplicate({
                                    id: viewInsight.id,
                                    appId: viewInsight.appId,
                                  });
                                }}
                              >
                                Duplicate
                              </MenuItem>
                              <MenuItem
                                fontSize="xs"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteViewInsight({
                                    id: viewInsight.id,
                                    appId: viewInsight.appId,
                                  });
                                }}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}

                        {(isPaywalled || isBlurred) && !isEditing && (
                          <Flex position="relative" bottom="2px">
                            <PaywallPopover
                              feature="company insights"
                              redirect={`group/${groupId}`}
                            >
                              <LockIcon h="15px" w="15px" color="purple.500" />
                            </PaywallPopover>
                          </Flex>
                        )}
                      </div>
                    </hc.Title>
                  </div>
                </Flex>
              </Flex>
            </hc.Header>
            <hc.Body isLoading={isLoading} maxW="full" h="full">
              {!isEmpty && children}
              {isEmpty && (
                <Center ml={6} w="full" h="full">
                  <div className="flex flex-col items-center gap-4">
                    <div className="rounded  bg-purple-50 p-2">
                      <MoonIcon className="h-4 text-purple-500" />
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-xs font-medium">Nothing to see here</p>
                      <p className="text-xs text-gray-600">
                        {hasTimerangePicker
                          ? "Try and increase the time range"
                          : "Come back later to see this insight"}
                      </p>
                    </div>
                  </div>
                </Center>
              )}
            </hc.Body>
          </hc.Container>
        )}
      </HighlightCard>
      <React.Suspense fallback={<div />}>
        {!isBuilderOpen ? null : viewInsight.query ? (
          <SQLEditor
            isOpen={isBuilderOpen}
            onClose={() => setIsBuilderOpen(false)}
            viewId={viewInsight.viewId}
            viewInsight={viewInsight}
            execute
          />
        ) : (
          isBuilderOpen && (
            <InsightBuilder
              isOpen={isBuilderOpen}
              onClose={() => setIsBuilderOpen(false)}
              viewId={viewInsight.viewId}
              viewInsight={viewInsight}
            />
          )
        )}
      </React.Suspense>
      {isFullModeOpen && (
        <FullInsightModal
          viewInsight={viewInsight}
          isOpen={isFullModeOpen}
          onClose={() => setIsFullModeOpen(false)}
          isBlurred={isBlurred}
          groupId={groupId}
        />
      )}
    </>
  );
};
