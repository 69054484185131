import { viewsApi } from "core/models/views";
import { viewInsightsApi } from "core/models/viewInsights";
import { usersApi } from "core/models/users";
import { traitsApi } from "core/models/traits";
import toasts from "core/models/toasts";
import { tableTraitConfigApi } from "core/models/tableTraitConfig";
import { slackAppsApi } from "core/models/slackApps";
import { searchApi } from "core/models/search";
import { reportsApi } from "core/models/reports";
import report from "core/models/report/slice";
import { reportApi } from "core/models/report";
import { queriesApi } from "core/models/queries";
import { propertiesApi } from "core/models/properties";
import editingPinnedReports from "core/models/pinnedReports/index";
import { pinnedReportsApi } from "core/models/pinnedReports";
import { peopleApi } from "core/models/people";
import { paymentApi } from "core/models/payment";
import { pagesApi } from "core/models/pages";
import { onboardingChecklistApi } from "core/models/onboardingChecklist";
import appObjects from "core/models/objects/index";
import navigation from "core/models/navigation";
import { labelsApi } from "core/models/labels";
import { insightsApi } from "core/models/insights";
import { importTasksApi } from "core/models/importTasks";
import { hubspotApi } from "core/models/hubspot";
import { hubspotObjectsApi } from "core/models/hubspot/objects";
import { homeApi } from "core/models/home";
import { groupsApi } from "core/models/groups";
import group from "core/models/group";
import { favouritesApi } from "core/models/favourites";
import { eventsApi } from "core/models/events";
import { eventLogsApi } from "core/models/eventLogs";
import error from "core/models/error";
import { enrichedEmailApi } from "core/models/enrichedEmail";
import { enrichedDomainApi } from "core/models/enrichedDomain";
import { editEventAlertSliceReducer } from "core/models/editEventAlert";
import { digestInsightsApi } from "core/models/digestInsights";
import { debuggerApi } from "core/models/debugger";
import { dataMappingsApi } from "core/models/dataMappings";
import { dataDeletionRequestsApi } from "core/models/dataDeletionRequests";
import { crmSyncSettingsApi } from "core/models/crmSyncSettings";
import { crmSyncsApi } from "core/models/crmSyncs";
import { crmErrorsApi } from "core/models/crmErrors";
import { crmEntitiesApi } from "core/models/crmEntities";
import { contactsApi } from "core/models/contacts";
import contact from "core/models/contact";
import { computedTraitsApi } from "core/models/computedTraits";
import { companiesApi } from "core/models/companies";
import { changelogsApi } from "core/models/changelogs";
import { authApi } from "core/models/auth";
import { audiencesApi } from "core/models/audiences";
import { audienceListApi } from "core/models/audienceList";
import { attioApi } from "core/models/attio";
import { appsApi } from "core/models/apps";
import { appObjectApi } from "core/models/appObjects";
import app from "core/models/app";
import { apiKeysApi } from "core/models/apiKeys";
import { alertsApi } from "core/models/alerts";
import ai from "core/models/ai";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { eventLog } from "../models/selected-event-log";
import { salesforceSettingsApi } from "core/models/salesforceSettings";
import { sqlApi } from "core/models/sql";

export const reducer = {
  app,
  report,
  contact,
  ai,
  appObjects,
  toasts,
  error,
  editingPinnedReports,
  navigation,
  group,
  eventLog,
  editEventAlert: editEventAlertSliceReducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  [changelogsApi.reducerPath]: changelogsApi.reducer,
  [apiKeysApi.reducerPath]: apiKeysApi.reducer,
  [appObjectApi.reducerPath]: appObjectApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [crmErrorsApi.reducerPath]: crmErrorsApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [labelsApi.reducerPath]: labelsApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [pagesApi.reducerPath]: pagesApi.reducer,
  [queriesApi.reducerPath]: queriesApi.reducer,
  [insightsApi.reducerPath]: insightsApi.reducer,
  [hubspotApi.reducerPath]: hubspotApi.reducer,
  [hubspotObjectsApi.reducerPath]: hubspotObjectsApi.reducer,
  [onboardingChecklistApi.reducerPath]: onboardingChecklistApi.reducer,
  [propertiesApi.reducerPath]: propertiesApi.reducer,
  [traitsApi.reducerPath]: traitsApi.reducer,
  [eventLogsApi.reducerPath]: eventLogsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [audiencesApi.reducerPath]: audiencesApi.reducer,
  [tableTraitConfigApi.reducerPath]: tableTraitConfigApi.reducer,
  [enrichedDomainApi.reducerPath]: enrichedDomainApi.reducer,
  [enrichedEmailApi.reducerPath]: enrichedEmailApi.reducer,
  [audienceListApi.reducerPath]: audienceListApi.reducer,
  [pinnedReportsApi.reducerPath]: pinnedReportsApi.reducer,
  [dataDeletionRequestsApi.reducerPath]: dataDeletionRequestsApi.reducer,
  [slackAppsApi.reducerPath]: slackAppsApi.reducer,
  [appsApi.reducerPath]: appsApi.reducer,
  [viewsApi.reducerPath]: viewsApi.reducer,
  [viewInsightsApi.reducerPath]: viewInsightsApi.reducer,
  [digestInsightsApi.reducerPath]: digestInsightsApi.reducer,
  [importTasksApi.reducerPath]: importTasksApi.reducer,
  [debuggerApi.reducerPath]: debuggerApi.reducer,
  [attioApi.reducerPath]: attioApi.reducer,
  [dataMappingsApi.reducerPath]: dataMappingsApi.reducer,
  [computedTraitsApi.reducerPath]: computedTraitsApi.reducer,
  [crmSyncSettingsApi.reducerPath]: crmSyncSettingsApi.reducer,
  [crmSyncsApi.reducerPath]: crmSyncsApi.reducer,
  [crmEntitiesApi.reducerPath]: crmEntitiesApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [peopleApi.reducerPath]: peopleApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [favouritesApi.reducerPath]: favouritesApi.reducer,
  [salesforceSettingsApi.reducerPath]: salesforceSettingsApi.reducer,
  [sqlApi.reducerPath]: sqlApi.reducer,
};

export const middleware = [
  alertsApi.middleware,
  apiKeysApi.middleware,
  appObjectApi.middleware,
  authApi.middleware,
  paymentApi.middleware,
  crmErrorsApi.middleware,
  homeApi.middleware,
  reportsApi.middleware,
  labelsApi.middleware,
  eventsApi.middleware,
  pagesApi.middleware,
  queriesApi.middleware,
  insightsApi.middleware,
  hubspotApi.middleware,
  hubspotObjectsApi.middleware,
  onboardingChecklistApi.middleware,
  propertiesApi.middleware,
  traitsApi.middleware,
  eventLogsApi.middleware,
  usersApi.middleware,
  contactsApi.middleware,
  groupsApi.middleware,
  paymentApi.middleware,
  companiesApi.middleware,
  audiencesApi.middleware,
  tableTraitConfigApi.middleware,
  enrichedDomainApi.middleware,
  enrichedEmailApi.middleware,
  audienceListApi.middleware,
  pinnedReportsApi.middleware,
  dataDeletionRequestsApi.middleware,
  slackAppsApi.middleware,
  appsApi.middleware,
  viewsApi.middleware,
  viewInsightsApi.middleware,
  digestInsightsApi.middleware,
  importTasksApi.middleware,
  debuggerApi.middleware,
  attioApi.middleware,
  dataMappingsApi.middleware,
  computedTraitsApi.middleware,
  changelogsApi.middleware,
  crmSyncSettingsApi.middleware,
  crmSyncsApi.middleware,
  crmEntitiesApi.middleware,
  searchApi.middleware,
  peopleApi.middleware,
  reportApi.middleware,
  favouritesApi.middleware,
  salesforceSettingsApi.middleware,
  sqlApi.middleware,
];

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(middleware),
  ],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
