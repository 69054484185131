import { Navigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ViewContainer } from "modules/Views/Container";
import { Header } from "modules/Groups/Group/Header";
import { EnrichmentCard } from "modules/Groups/Group/EnrichmentCard";
import { GroupContacts } from "modules/Contacts/GroupContacts";
import ContactEventLog from "modules/Contacts/ContactEventLog";
import { ITrait } from "core/types/Trait";
import { IView, useUpdateViewMutation } from "core/models/views";
import { selector as editingPinnedReportsSelector } from "core/models/pinnedReports/selectors";
import { toggleEdit } from "core/models/pinnedReports/actions";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { useRecentlyViewedPeople } from "core/hooks/useRecentlyViewedPeople";
import { useNavigation } from "core/hooks/useNavigation";
import { useGroup } from "core/hooks/useGroup";
import { useCompanyColumns } from "core/hooks/useCompanyColumns";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  StyledTabs,
} from "core/design-system/components/Tabs";
import { Breadcrumbs } from "core/design-system/components/Breadcrumbs";
import { TRAIT_LEVEL } from "core/constants/traits";
import { baseUrl } from "core/constants/paywall";
import WarningBanner from "core/components/WarningBanner";
import { TraitsContainer } from "core/components/Traits/Container";
import { PaywallModal } from "core/components/Paywall/Modal";
import { PageContainer } from "core/components/PageContainer";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { UseInternalUsersToggle } from "core/components/App/UseInternalUsersToggle";
import { Squares2X2Icon, SquaresPlusIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Flex,
  Skeleton,
  TabPanels,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { AddMenu } from "modules/Views/AddMenu";
import useFlag from "core/hooks/useFlag";
import { SQL_EDITOR } from "core/constants/features";

function UnidentifiedGroupDescription() {
  return (
    <>
      We received track calls, but no group calls for this company. Send a group
      call to add information about this company.{" "}
      <Text
        as="a"
        fontWeight="semibold"
        color="purple.500"
        target="blank"
        href="https://segment.com/docs/connections/spec/group/"
      >
        Learn more
      </Text>
    </>
  );
}

function Group() {
  const [view, setView] = useState<IView | null>();
  const [showPaywall, setShowPaywall] = useState<boolean>(false);
  const { save } = useRecentlyViewedPeople();
  const { views, isLoadingViews } = useViews({
    location: ViewLocation.Company,
    pinned: false,
  });
  const { isEditing } = useSelector(editingPinnedReportsSelector);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showSQLModal, setShowSQLModal] = useState(false);
  const { appId, groupId: encodedGroupId } = useParams<{
    appId: string;
    groupId: string;
  }>() as {
    appId: string;
    groupId: string;
  };
  const groupId = decodeURIComponent(encodedGroupId);
  const { fetchGroup, groupLoading, groupError, group } = useGroup({
    id: groupId,
  });
  const dispatch = useDispatch();
  const redirectUrl = `${baseUrl}/a/${appId}/home`;
  const { groupAppObject } = useAppObjects();
  const { columns } = useCompanyColumns();
  const { navigation, toggleSidebar } = useNavigation();
  const [updateView, { isLoading: isUpdatingView }] = useUpdateViewMutation();

  const hasSQLEditor = useFlag(SQL_EDITOR);

  group && groupAppObject && save(appId, group, groupAppObject.objectType);

  useEffect(() => {
    fetchGroup();
  }, [fetchGroup]);

  useEffect(() => {
    if (views && views?.length > 0) setView(views[0]);
  }, [views]);

  useEffect(() => {
    if (navigation.isOpen) toggleSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!groupId) {
    return <Navigate to={`/a/${appId}/groups`} />;
  }

  const pages = [
    {
      name: groupAppObject?.pluralName ?? "Workspaces",
      path: `/a/${appId}/people`,
      current: false,
    },
    {
      name: group?.name ?? "Unknown",
      path: `/a/${appId}/group/${encodedGroupId}`,
      current: true,
    },
  ];

  return (
    <PageContainer
      className="h-full bg-gray-25"
      bgColor={isEditing ? "bg-gray-50" : "bg-white"}
      px={columns === 3 ? "0px" : "20px"}
      minW="2xl"
      shadow={!isEditing}
    >
      <div className="mb-6">
        <Breadcrumbs pages={pages} />
      </div>
      {groupError && (
        <WarningBanner
          title="This company was not identified"
          description={<UnidentifiedGroupDescription />}
        />
      )}

      <Flex w="full" direction="row" gridGap={5} minW="100%" maxW="full">
        <Box minW="340px" w="30%" opacity={isEditing ? 0.5 : 1}>
          <div className="flex flex-col gap-5">
            {groupLoading ? (
              <Skeleton borderRadius="lg" height="264px" w="100%" />
            ) : (
              <Header />
            )}
            {appId === "9" && (
              <Flex>
                <Tooltip label="Don't worry! This is only visible to our June app">
                  <Button
                    colorScheme="purple"
                    as="a"
                    href={`https://api.june.so/admin/app/${groupId}`}
                    target="_blank"
                    rightIcon={<ExternalLinkIcon />}
                    w="100%"
                  >
                    Admin page
                  </Button>
                </Tooltip>
              </Flex>
            )}
            {groupLoading ? (
              <Skeleton borderRadius="lg" height="300px" w="100%" />
            ) : (
              <TraitsContainer
                id={groupId}
                traitLevel={TRAIT_LEVEL.GROUP}
                traitKeyValues={group?.traits as ITrait[]}
                showError={false}
              />
            )}
            {groupLoading ? (
              <Skeleton borderRadius="lg" height="300px" w="100%" />
            ) : (
              <>{group && <EnrichmentCard />}</>
            )}
          </div>
        </Box>
        <div className="max-w-full">
          {groupLoading ? (
            <>
              <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
              <Skeleton borderRadius="lg" height="300px" w="100%" mb={4} />
              <Skeleton borderRadius="lg" height="200px" w="100%" mb={4} />
            </>
          ) : (
            <StyledTabs isLazy defaultIndex={0} w="980px" maxW="full">
              <StyledTabList
                boxShadow="sm"
                w="100%"
                opacity={isEditing ? 0.5 : 1}
              >
                <StyledTab>Overview</StyledTab>
                <StyledTab>Feed</StyledTab>
                <StyledTab>Users</StyledTab>
              </StyledTabList>
              <TabPanels maxW="full">
                <StyledTabPanel
                  minW={columns === 3 ? "980px" : "790px"}
                  w="100%"
                >
                  <div className="mb-4 flex items-center justify-between">
                    <p className="text-lg font-semibold">Overview</p>
                    <Flex justifyContent="flex-end" w="100%" gap={1}>
                      {view && (
                        <UseInternalUsersToggle
                          isEnabled={view.useInternalUsers}
                          isLoading={isLoadingViews || isUpdatingView}
                          onChange={(useInternalUsers) => {
                            updateView({
                              id: Number(view.id),
                              appId: Number(appId),
                              useInternalUsers,
                            });
                          }}
                        />
                      )}
                      {hasSQLEditor ? (
                        <AddMenu
                          onAddInsightClick={() => {
                            setShowAddModal(!showAddModal);
                            dispatch(toggleEdit());
                          }}
                          onAddSQLClick={() => {
                            setShowSQLModal(!showSQLModal);
                            dispatch(toggleEdit());
                          }}
                        />
                      ) : (
                        <Button
                          className="company-profile-add-insight-button"
                          variant="ghost"
                          onClick={() => {
                            dispatch(toggleEdit());
                            setShowAddModal(!showAddModal);
                          }}
                          leftIcon={<SquaresPlusIcon className="h-4 w-4" />}
                        >
                          Add
                        </Button>
                      )}

                      <Button
                        key={isEditing ? "edit" : "done"}
                        variant={isEditing ? "solid" : "ghost"}
                        colorScheme={isEditing ? "purple" : "gray"}
                        onClick={() => {
                          dispatch(toggleEdit());
                        }}
                        leftIcon={<Squares2X2Icon className="h-4 w-4" />}
                      >
                        {isEditing ? "Done" : "Edit"}
                      </Button>
                    </Flex>
                  </div>
                  <Box pb={10} w="full">
                    {view && (
                      <ViewContainer
                        location={ViewLocation.Company}
                        viewId={Number(view.id)}
                        columns={columns}
                        width={columns === 3 ? "315px" : "385px"}
                        paywallInsights
                        showAddModal={showAddModal}
                        setShowAddModal={setShowAddModal}
                        showSQLModal={showSQLModal}
                        setShowSQLModal={setShowSQLModal}
                      />
                    )}
                  </Box>
                </StyledTabPanel>
                <StyledTabPanel
                  minW={columns === 3 ? "980px" : "790px"}
                  w="100%"
                >
                  <div className="mb-2 flex w-full justify-end">
                    {view && (
                      <UseInternalUsersToggle
                        isEnabled={view.useInternalUsers}
                        isLoading={isLoadingViews || isUpdatingView}
                        onChange={(useInternalUsers) => {
                          updateView({
                            id: Number(view.id),
                            appId: Number(appId),
                            useInternalUsers,
                          });
                        }}
                      />
                    )}
                  </div>
                  {group && (
                    <ContactEventLog
                      key={JSON.stringify(view)}
                      appId={Number(appId)}
                      groupId={groupId}
                      groupType={GroupType.Group}
                      useInternalUsers={view?.useInternalUsers}
                    />
                  )}
                </StyledTabPanel>
                <StyledTabPanel
                  minW={columns === 3 ? "980px" : "790px"}
                  w="100%"
                >
                  <div className="mb-2 flex w-full justify-end">
                    {view && (
                      <UseInternalUsersToggle
                        isEnabled={view.useInternalUsers}
                        isLoading={isLoadingViews || isUpdatingView}
                        onChange={(useInternalUsers) => {
                          updateView({
                            id: Number(view.id),
                            appId: Number(appId),
                            useInternalUsers,
                          });
                        }}
                      />
                    )}
                  </div>
                  <GroupContacts
                    key={JSON.stringify(view)}
                    showListActions={false}
                    useInternalUsers={view?.useInternalUsers}
                  />
                </StyledTabPanel>
              </TabPanels>
            </StyledTabs>
          )}
          {showPaywall && (
            <PaywallModal redirectUrl={redirectUrl}>
              {(paywallModal) => (
                <paywallModal.Modal
                  isOpen
                  onClose={() => setShowPaywall(false)}
                />
              )}
            </PaywallModal>
          )}
        </div>
      </Flex>
    </PageContainer>
  );
}

export default Group;
