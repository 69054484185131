import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { ITemplateConfig } from "core/types/TemplateConfig";
import {
  IViewInsight,
  useUpdateViewInsightMutation,
} from "core/models/viewInsights";
import { getReportConfig } from "core/helpers/getReportConfig";
import colors, {
  IColorPalette,
} from "core/design-system/constants/theme/colors";
import {
  ArrowTrendingUpIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  PresentationChartLineIcon,
  ShareIcon,
  Squares2X2Icon,
  StarIcon,
  TableCellsIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/16/solid";
import { useReportSetup } from "./useReportSetup";
import {
  GraphType,
  Measure,
  Size,
  Summary,
  Interval,
  State,
} from "core/types/ViewInsight";
import { useAppObjects } from "./useAppObjects";

export const GraphTypeToIcon = {
  [GraphType.Bar]: <ChartBarIcon className="h-4 w-4" />,
  [GraphType.Area]: <PresentationChartLineIcon className="h-4 w-4" />,
  [GraphType.Line]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [GraphType.Table]: <TableCellsIcon className="h-4 w-4" />,
  [GraphType.Scatter]: <ShareIcon className="h-4 w-4" />,
  [GraphType.StackedLine]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [GraphType.StackedBar]: <ChartBarIcon className="h-4 w-4" />,
  [GraphType.CohortGraph]: <Squares2X2Icon className="h-4 w-4" />,
  [GraphType.ContributionGraph]: <UserGroupIcon className="h-4 w-4" />,
  [GraphType.Trend]: <ArrowTrendingUpIcon className="h-4 w-4" />,
};

export const MeasureToIcon = {
  [Measure.ActiveUsers]: <UsersIcon className="h-4 w-4" />,
  [Measure.NewUsers]: <UsersIcon className="h-4 w-4" />,
  [Measure.Features]: <CursorArrowRaysIcon className="h-4 w-4" />,
  [Measure.Retention]: <ArrowTrendingUpIcon className="h-4 w-4" />,
  [Measure.Funnel]: <ChartBarIcon className="h-4 w-4 scale-x-[-1]" />,
  [Measure.Power]: <StarIcon className="h-4 w-4" />,
};

export const InsightTypeConfigs = {
  [Measure.ActiveUsers]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: [
      GraphType.Area,
      GraphType.Bar,
      GraphType.Table,
      GraphType.ContributionGraph,
      GraphType.Trend,
    ],
    summaries: [Summary.Average],
    intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
  },
  [Measure.NewUsers]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: [GraphType.Area, GraphType.Bar, GraphType.Trend],
    summaries: [Summary.Total],
    intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
  },
  [Measure.Features]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: [
      GraphType.StackedLine,
      GraphType.StackedBar,
      GraphType.Scatter,
      GraphType.Table,
    ],
    summaries: [Summary.Total, Summary.Average],
    intervals: [Interval.Daily, Interval.Weekly, Interval.Monthly],
  },
  [Measure.Retention]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: [GraphType.Area, GraphType.CohortGraph],
    summaries: [],
    intervals: [],
  },
  [Measure.Funnel]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: [GraphType.Bar, GraphType.Area],
    summaries: [Summary.Total],
    intervals: [],
  },
  [Measure.Power]: {
    sizes: [Size.Small, Size.Medium, Size.Large],
    graphTypes: [GraphType.Bar, GraphType.Table],
    summaries: [],
    intervals: [Interval.Weekly, Interval.Monthly],
  },
} as {
  [key in Measure]: {
    sizes: Size[];
    graphTypes: GraphType[];
    summaries: Summary[];
    intervals: Interval[];
  };
};

export const COLOR_PALETTES = [
  colors.purple,
  colors.red,
  colors.green,
  colors.blue,
  colors.yellow,
  colors.orange,
  colors.teal,
  colors.pink,
];

export const COLOR_NAME_TO_PALETTE = {
  purple: colors.purple,
  red: colors.red,
  green: colors.green,
  blue: colors.blue,
  yellow: colors.yellow,
  orange: colors.orange,
  teal: colors.teal,
  pink: colors.pink,
} as { [key in string]: IColorPalette };

export const GRID_WIDTH = {
  [Size.Small]: 1,
  [Size.Medium]: 2,
  [Size.Large]: 3,
};

export const GRID_HEIGHT = {
  [Size.Small]: 1,
  [Size.Medium]: 1,
  [Size.Large]: 1,
};

export const GRID_WIDTH_TO_SIZE = [null, Size.Small, Size.Medium, Size.Large];

export const COLOR_NAMES = [
  "purple",
  "red",
  "green",
  "blue",
  "yellow",
  "orange",
  "teal",
  "pink",
];

function getGraphType(viewInsight: IViewInsight) {
  if (viewInsight.graphType) return viewInsight.graphType;
  if (viewInsight.measure)
    return InsightTypeConfigs[viewInsight.measure].graphTypes[0];

  return GraphType.Area;
}

export const useEditViewInsight = ({
  viewInsight,
  loadReport,
}: {
  viewInsight: IViewInsight;
  loadReport: boolean;
}) => {
  const { userAppObject } = useAppObjects();
  const { appId } = useParams();
  const [measure, setMeasure] = useState(viewInsight.measure);
  const [appObject, setAppObject] = useState(
    viewInsight.appObject || userAppObject,
  );
  const [graphType, setGraphType] = useState(getGraphType(viewInsight));
  const [useViewAudience, setUseViewAudience] = useState(
    viewInsight.useViewAudience,
  );
  const config = getReportConfig(
    viewInsight.insight.reportType,
  ) as ITemplateConfig;

  const [size, setSize] = useState(GRID_WIDTH_TO_SIZE[viewInsight.gridWidth]);
  const [colorPalette, setColorPalette] = useState(
    COLOR_NAME_TO_PALETTE[viewInsight.color],
  );
  const [title, setTitle] = useState(
    viewInsight.title || viewInsight.report.name || "Untitled",
  );
  const [interval, setInterval] = useState(
    viewInsight.interval || Interval.Daily,
  );
  const [updateViewInsight] = useUpdateViewInsightMutation();
  const { currentReport, loadReportSetup, updateReportSetup } =
    useReportSetup();

  const isInstantiated = useRef(false);

  useEffect(() => {
    if (loadReport)
      loadReportSetup({
        report: viewInsight.report,
        setup: config.setup,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInsight, loadReport]);

  useEffect(() => {
    if (!measure) return;

    if (
      graphType &&
      !InsightTypeConfigs[measure].graphTypes.includes(graphType)
    )
      setGraphType(InsightTypeConfigs[measure].graphTypes[0]);
    if (size && !InsightTypeConfigs[measure].sizes.includes(size))
      setSize(InsightTypeConfigs[measure].sizes[0]);
    if (interval && !InsightTypeConfigs[measure].intervals.includes(interval))
      setInterval(InsightTypeConfigs[measure].intervals[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure]);

  useEffect(() => {
    if (isInstantiated.current && loadReport) {
      onUpdate();
    } else {
      isInstantiated.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure, graphType, interval, size, appObject, useViewAudience]);

  function onCreate() {
    updateViewInsight({
      id: viewInsight.id,
      appId: Number(appId),
      title,
      graphType,
      color: COLOR_NAMES[COLOR_PALETTES.indexOf(colorPalette)],
      interval,
      measure,
      gridWidth: GRID_WIDTH[size || Size.Small],
      gridHeight: GRID_HEIGHT[size || Size.Small],
      objectType: appObject.objectType,
      state: State.Persisted,
      useViewAudience,
    });
  }

  function onUpdate() {
    updateViewInsight({
      id: viewInsight.id,
      appId: Number(appId),
      title,
      graphType,
      color: COLOR_NAMES[COLOR_PALETTES.indexOf(colorPalette)],
      interval,
      measure,
      gridWidth: GRID_WIDTH[size || Size.Small],
      gridHeight: GRID_HEIGHT[size || Size.Small],
      objectType: appObject.objectType,
      useViewAudience,
    });
  }

  function onUpdateReport() {
    updateReportSetup({ setup: config.setup });
  }

  return {
    report: currentReport,
    templateConfig: config,
    measure,
    graphType,
    size,
    title,
    interval,
    colorPalette,
    colors: COLOR_PALETTES,
    appObject,
    useViewAudience,
    setMeasure,
    setGraphType,
    setSize,
    setColorPalette,
    setTitle,
    setInterval,
    onCreate,
    onUpdate,
    setAppObject,
    setUseViewAudience,
    onUpdateReport,
  };
};
