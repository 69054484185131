import { IReport } from "core/types/Report";
import { IViewInsight } from "core/models/viewInsights";
import { useEditViewInsight } from "core/hooks/useEditViewInsight";
import { Size } from "core/types/ViewInsight";
import { Button } from "@chakra-ui/react";
import { BaseModal } from "./Base";
import { LayoutContainer } from "../Builder/LayoutContainer";
import { useParams } from "react-router-dom";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { Plan } from "core/types/App";

export const InsightBuilder: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  viewId: number;
  viewInsight: IViewInsight;
  setShowChooseExistingReportInsights?: (show: boolean) => void;
  isAddingNewInsight?: boolean;
}> = ({
  isOpen,
  onClose,
  viewInsight,
  setShowChooseExistingReportInsights,
  isAddingNewInsight,
}) => {
  const { groupId } = useParams();
  const {
    report,
    templateConfig,
    measure,
    graphType,
    size,
    title,
    interval,
    colorPalette,
    colors,
    appObject,
    useViewAudience,
    setMeasure,
    setAppObject,
    setGraphType,
    setSize,
    setColorPalette,
    setTitle,
    setInterval,
    onCreate,
    onUpdate,
    setUseViewAudience,
    onUpdateReport,
  } = useEditViewInsight({ viewInsight, loadReport: isOpen });

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      header={null}
      body={
        isOpen &&
        report &&
        templateConfig &&
        viewInsight && (
          <LayoutContainer
            viewInsight={viewInsight}
            report={report as IReport}
            templateConfig={templateConfig}
            measure={measure}
            appObject={appObject}
            graphType={graphType}
            size={size || Size.Small}
            colorPalette={colorPalette}
            colors={colors}
            title={title}
            interval={interval}
            setMeasure={setMeasure}
            setAppObject={setAppObject}
            setGraphType={setGraphType}
            setSize={setSize}
            setColorPalette={setColorPalette}
            setTitle={setTitle}
            setInterval={setInterval}
            isAddingNewInsight={isAddingNewInsight}
            useViewAudience={useViewAudience}
            setUseViewAudience={setUseViewAudience}
            onUpdateReport={onUpdateReport}
          />
        )
      }
      footer={
        viewInsight?.persisted ? (
          <Button
            colorScheme="purple"
            onClick={() => {
              onUpdate();
              onClose();
              onUpdateReport();
            }}
          >
            Done
          </Button>
        ) : (
          <div className="flex w-full items-center justify-between">
            {setShowChooseExistingReportInsights && (
              <PaywallPopover
                feature="company profile insights"
                redirect="people"
                plan={Plan.Growth}
                overridePaywall={!Boolean(groupId)}
              >
                <Button
                  colorScheme="purple"
                  variant="ghost"
                  onClick={() => setShowChooseExistingReportInsights(true)}
                >
                  Choose existing insights
                </Button>
              </PaywallPopover>
            )}
            <PaywallPopover
              feature="company profile insights"
              redirect="people"
              plan={Plan.Growth}
              overridePaywall={!Boolean(groupId)}
            >
              <Button
                colorScheme="purple"
                onClick={() => {
                  onUpdateReport();
                  onCreate();
                  onClose();
                }}
              >
                Add insight
              </Button>
            </PaywallPopover>
          </div>
        )
      }
    />
  );
};
