import { useState } from "react";
import moment from "moment";
import { Integration } from "core/models/dataMappings";
import { ICrmSync } from "core/models/crmSyncs";
import { CrmEntityState } from "core/models/crmEntities";
import { AppObjectType, IAppObject } from "core/models/appObjects";
import usePagination from "core/hooks/usePagination";
import { useCrmEntities } from "core/hooks/useCrmEntities";
import { ToggleButton } from "core/components/Toggle";
import {
  GroupListSingleGroup,
  GroupType,
} from "core/components/Group/GroupListSingleGroup";
import { Drilldown } from "core/components/Drilldown";
import { UserListSingleContact } from "core/components/Contact/UserListSingleContact";
import {
  ComponentDefaultProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { TraitsPopover } from "./TraitsPopover";

interface IRecordsDrilldown extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
  isOpen: boolean;
  onClose: () => void;
  crmSync?: ICrmSync;
}

export enum CrmEntitiesOperator {
  LessThan = "less than",
  MoreThan = "more than",
}

export const RecordsDrilldown: React.FC<IRecordsDrilldown> = ({
  appObject,
  integration,
  isOpen,
  onClose,
  crmSync,
}) => {
  const [state, setState] = useState<CrmEntityState>(CrmEntityState.All);
  const [time, setTime] = useState<number>(3600);
  const [operator, setOperator] = useState<CrmEntitiesOperator>(
    CrmEntitiesOperator.LessThan,
  );
  const { currentPage, nextPage, setPage } = usePagination({
    withURL: false,
    persist: false,
  });
  const { crmEntities, pagy, isLoadingCrmEntities } = useCrmEntities({
    integration,
    objectType: appObject.objectType,
    page: currentPage,
    state,
    crmSync,
    time,
    operator,
  });

  const TIME_OPTIONS = {
    600: "10 minutes ago",
    3600: "1 hour ago",
    86400: "1 day ago",
    604800: "1 week ago",
    2592000: "1 month ago",
  } as { [key: number]: string };

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={appObject.pluralName}
              isLoading={isLoadingCrmEntities}
            />
            <Drilldown.Toggle>
              <ToggleButton
                isSelected={state === CrmEntityState.All}
                onToggle={() => {
                  setPage(1);
                  setState(CrmEntityState.All);
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                isSelected={state === CrmEntityState.Synced}
                onToggle={() => {
                  setPage(1);
                  setState(CrmEntityState.Synced);
                }}
              >
                Synced
              </ToggleButton>
              <ToggleButton
                isSelected={state === CrmEntityState.NotSynced}
                onToggle={() => {
                  setPage(1);
                  setState(CrmEntityState.NotSynced);
                }}
              >
                Skipped
              </ToggleButton>
            </Drilldown.Toggle>
            {!crmSync && (
              <div className="border-1 rounded-md border-gray-200 bg-gray-50 px-2 py-1.5 text-sm font-medium shadow-sm">
                Last synced{" "}
                <Menu matchWidth>
                  <MenuButton className="rounded-md px-1 font-semibold hover:bg-gray-200">
                    {operator}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => setOperator(CrmEntitiesOperator.LessThan)}
                    >
                      less than
                    </MenuItem>
                    <MenuItem
                      onClick={() => setOperator(CrmEntitiesOperator.MoreThan)}
                    >
                      more than
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Menu matchWidth>
                  <MenuButton className="rounded-md px-1 font-semibold hover:bg-gray-200">
                    {TIME_OPTIONS[time]}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => setTime(600)}>
                      10 minutes ago
                    </MenuItem>
                    <MenuItem onClick={() => setTime(3600)}>
                      1 hour ago
                    </MenuItem>
                    <MenuItem onClick={() => setTime(86400)}>
                      1 day ago
                    </MenuItem>
                    <MenuItem onClick={() => setTime(604800)}>
                      1 week ago
                    </MenuItem>
                    <MenuItem onClick={() => setTime(2592000)}>
                      1 month ago
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            )}
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              key={state}
              items={crmEntities}
              hasMore={crmEntities.length < Number(pagy?.count)}
              itemRenderer={({ item }) =>
                appObject.objectType === AppObjectType.User ? (
                  <UserListSingleContact
                    key={item.entityId}
                    id={item.entityId}
                    traits={item.traits}
                    description={
                      state === CrmEntityState.NotSynced ? (
                        <p className="text-xs">
                          <p className="inline-block capitalize">Skipped</p>{" "}
                          {moment.utc(item.updatedAt).fromNow()}
                        </p>
                      ) : (
                        <p className="text-xs">
                          <p className="inline-block capitalize">
                            {item.action}
                          </p>{" "}
                          {moment.utc(item.updatedAt).fromNow()} with{" "}
                          <TraitsPopover traits={item.traits} />
                        </p>
                      )
                    }
                  />
                ) : (
                  <GroupListSingleGroup
                    key={item.entityId}
                    id={item.entityId}
                    traits={item.traits}
                    groupType={
                      appObject.objectType === AppObjectType.Group
                        ? GroupType.Group
                        : GroupType.Company
                    }
                    description={
                      state === CrmEntityState.NotSynced ? (
                        <p className="text-xs">
                          <p className="inline-block capitalize">Skipped</p>{" "}
                          {moment.utc(item.updatedAt).fromNow()}
                        </p>
                      ) : (
                        <p className="text-xs">
                          <p className="inline-block capitalize">
                            {item.action}
                          </p>{" "}
                          {moment.utc(item.updatedAt).fromNow()} with{" "}
                          <TraitsPopover traits={item.traits} />
                        </p>
                      )
                    }
                  />
                )
              }
              loadNext={() => nextPage()}
              isLoading={isLoadingCrmEntities}
              emptyStateText={`No records synced`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};
