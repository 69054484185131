import humps from "humps";
import { IQuery } from "core/types/Query";
import { IPagy } from "core/types/Pagy";
import { baseQuery } from "core/initializers/rtk";
import { createApi } from "@reduxjs/toolkit/query/react";

import type { SnakeCasedPropertiesDeep } from "type-fest";
interface IGetQueriesArgs {
  appId: number;
}

interface ISearchQueriesArgs {
  appId: number;
  prompt: string;
}

interface IGetQueriesResponse {
  queries: IQuery[];
}

interface IGetQueryArgs {
  appId: number;
  id: number;
}

export interface ICreateQueryArgs {
  appId: number;
  reportId?: number;
  prompt?: string;
  query?: string;
  transcript?: string;
  sqlQuery?: string;
  error?: string;
}

interface IExecuteQueryArgs {
  appId: number;
  query: string;
  page: number;
  groupId?: string;
}

interface IExecuteQueryResponse {
  result: any;
  pagy: IPagy;
  query?: string;
  error?: string;
}

interface ISaveQueryArgs {
  queryId: number;
  appId: number;
}

interface IVoteForQueryArgs {
  appId: number;
  queryId: number;
  vote: number;
  feedback?: string;
}

export const queriesApi = createApi({
  baseQuery,
  reducerPath: "queriesApi",
  tagTypes: ["Query"],
  endpoints: (builder) => ({
    // Queries API endpoints
    getQueries: builder.query<IGetQueriesResponse, IGetQueriesArgs>({
      query: ({ appId }) => ({
        url: "/queries",
        params: {
          app_id: appId,
        },
      }),
      providesTags: ["Query"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetQueriesResponse>,
      ): IGetQueriesResponse =>
        humps.camelizeKeys(response) as IGetQueriesResponse,
    }),
    searchQueries: builder.query<IGetQueriesResponse, ISearchQueriesArgs>({
      query: ({ appId, prompt }) => ({
        url: "/queries/search",
        params: {
          app_id: appId,
          prompt: prompt,
        },
      }),
      providesTags: ["Query"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetQueriesResponse>,
      ): IGetQueriesResponse =>
        humps.camelizeKeys(response) as IGetQueriesResponse,
    }),
    getQuery: builder.query<IQuery, IGetQueryArgs>({
      query: ({ appId, id }) => ({
        url: `/queries/${id}`,
        params: {
          app_id: appId,
        },
      }),
      providesTags: ["Query"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IQuery>): IQuery =>
        humps.camelizeKeys(response) as IQuery,
    }),
    executeQuery: builder.query<IExecuteQueryResponse, IExecuteQueryArgs>({
      query: ({ appId, query, page, groupId }) => {
        return {
          url: `/queries/execute`,
          params: humps.decamelizeKeys({ appId, query, page, groupId }),
        };
      },
    }),
    createQuery: builder.mutation<IQuery, ICreateQueryArgs>({
      query: ({
        appId,
        reportId,
        prompt,
        query,
        transcript,
        sqlQuery,
        error,
      }) => {
        return {
          url: `/queries`,
          method: "POST",
          params: humps.decamelizeKeys({
            appId,
            reportId,
            prompt,
            query,
            transcript: transcript ? transcript : "",
            sqlQuery,
            error,
          }),
        };
      },
      invalidatesTags: ["Query"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IQuery>): IQuery =>
        humps.camelizeKeys(response) as IQuery,
    }),
    updateQuery: builder.mutation<
      IQuery,
      {
        appId: number;
        queryId: number;
        query?: string;
        showGraph?: boolean;
        graphXAxis?: string;
        graphYAxis?: string;
        graphType?: string;
        prompt?: string;
      }
    >({
      query: ({
        appId,
        queryId,
        query,
        showGraph,
        graphXAxis,
        graphYAxis,
        graphType,
        prompt,
      }) => ({
        url: `/queries/${queryId}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          query,
          showGraph,
          graphXAxis,
          graphYAxis,
          graphType,
          prompt,
        }),
      }),
      invalidatesTags: ["Query"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IQuery>): IQuery =>
        humps.camelizeKeys(response) as IQuery,
    }),
    saveQuery: builder.mutation<IQuery, ISaveQueryArgs>({
      query: ({ appId, queryId }) => ({
        url: `/queries/${queryId}/save_query`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId }),
      }),
      invalidatesTags: ["Query"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IQuery>): IQuery =>
        humps.camelizeKeys(response) as IQuery,
    }),
    voteForQuery: builder.mutation<IQuery, IVoteForQueryArgs>({
      query: ({ appId, queryId, feedback, vote }) => ({
        url: `/queries/${queryId}/vote_for_query`,
        method: "PUT",
        params: humps.decamelizeKeys({ appId, feedback, vote }),
      }),
      invalidatesTags: ["Query"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IQuery>): IQuery =>
        humps.camelizeKeys(response) as IQuery,
    }),
  }),
});

export const {
  useGetQueriesQuery,
  useLazyGetQueriesQuery,
  useGetQueryQuery,
  useLazyGetQueryQuery,
  useCreateQueryMutation,
  useLazySearchQueriesQuery,
  useLazyExecuteQueryQuery,
  useSaveQueryMutation,
  useVoteForQueryMutation,
  useUpdateQueryMutation,
} = queriesApi;
