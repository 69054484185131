import { DateRange } from "react-date-range";
import { Fragment, useState } from "react";
import { cx } from "helpers/cx";
import {
  IGroupedTimerangeOption,
  ITimerangeOption,
} from "core/types/TimerangeOption";
import { ICustomTimerange } from "core/hooks/useReportTimerange";
import { ABSOLUTE, CUSTOM_DATE, RELATIVE } from "core/constants/timerange";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Menu,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Button, Tooltip } from "@chakra-ui/react";

interface ITimerangeSection {
  options: IGroupedTimerangeOption[];
  label: string;
  timerangeType: typeof RELATIVE | typeof ABSOLUTE;
  relativeTimerange: ITimerangeOption;
  customTimerange: ICustomTimerange[];
  setCustomTimerange: (customTimerange: ICustomTimerange[]) => void;
  saveTimerange: (option: ITimerangeOption) => void;
  size?: string;
  tooltipText?: string;
  footerText?: string;
  customMenuButton?: React.ReactNode;
}

export const TimerangePicker: React.FC<ITimerangeSection> = ({
  options,
  label,
  timerangeType,
  relativeTimerange,
  customTimerange,
  setCustomTimerange,
  saveTimerange,
  size = "large",
  tooltipText,
  footerText = "Reports are in UTC time",
  customMenuButton,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [customDateSelected, setCustomDateSelected] = useState<boolean>(false);

  const toggleActive = () => setIsActive(!isActive);

  return (
    <div
      className={cx(
        "relative inline-block text-left",
        // We need two different z-index states because views can have multiple timerange pickers
        // And we want to make sure that the selected timerange picker is on top
        isActive ? "z-popover" : "z-0",
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <Tooltip
                label={tooltipText}
                placement={size === "small" ? "top" : "bottom"}
                hasArrow
              >
                <div>
                  <Menu.Button
                    as={Button}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleActive();
                    }}
                    data-testid="timepicker-button"
                    variant="ghost"
                    color="gray.500"
                    size={size === "small" ? "xs" : "sm"}
                    className={cx(open && "text-gray-900")}
                  >
                    {customMenuButton ? (
                      customMenuButton
                    ) : (
                      <>
                        <CalendarIcon
                          className={cx(
                            "mr-1 hidden h-3 w-3 lg:block",
                            size === "small" ? "h-4 w-4" : "h-5 w-5",
                          )}
                        />

                        <span className="relative flex-grow truncate">
                          {label}
                        </span>
                        {size !== "small" && (
                          <ChevronDownIcon
                            className="-mr-1 h-4 w-4"
                            aria-hidden="true"
                          />
                        )}
                      </>
                    )}
                  </Menu.Button>
                </div>
              </Tooltip>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {options.map((optionGroup: IGroupedTimerangeOption) => (
                    <div className="flex flex-col gap-1 px-1 py-1 ">
                      {optionGroup.options.map((option: ITimerangeOption) => {
                        const isSelected =
                          timerangeType === ABSOLUTE
                            ? option.value === "fixed"
                            : option.value === relativeTimerange.value;
                        return (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={cx(
                                  "group flex w-full items-center rounded-md px-2 py-1 text-sm text-gray-900 hover:bg-gray-50",
                                  isSelected && "bg-gray-100 text-gray-900",
                                )}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setCustomDateSelected(
                                    option.value === "fixed",
                                  );
                                  if (option.value !== "fixed")
                                    saveTimerange(option);
                                }}
                                data-testid={
                                  option.value === "fixed" &&
                                  "custom-timerange-button"
                                }
                              >
                                <CalendarIcon
                                  className={cx(
                                    "mr-2",
                                    size === "small" ? "h-4 w-4" : "h-5 w-5",
                                    isSelected
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-600",
                                  )}
                                />
                                {option.label}
                                {isSelected && (
                                  <CheckCircleIcon className="ml-auto h-5 w-5 text-gray-900" />
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  ))}
                  <div className="flex items-center gap-1 px-3 py-1">
                    <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                    <p className="text-xs text-gray-500">{footerText}</p>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
        {customDateSelected && (
          <Popover data-testid="custom-timerange-popover">
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel
                static
                className="w-100 absolute right-0 mt-2 origin-top-right rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <div onClick={(event) => event.stopPropagation()}>
                  <DateRange
                    ranges={customTimerange}
                    maxDate={new Date()}
                    onChange={(item: { selection: ICustomTimerange }) => {
                      setCustomTimerange([item.selection]);
                    }}
                    moveRangeOnFirstSelection={false}
                    showDateDisplay={false}
                  />
                </div>
                <div className="flex justify-end pb-4">
                  <div className="flex w-1/2 justify-end pr-4">
                    <PopoverButton
                      as="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        setCustomDateSelected(false);
                      }}
                      className="justify-center rounded-md bg-white px-3 py-2 text-xs font-semibold ring-1 ring-inset ring-gray-100 hover:bg-gray-50 focus-visible:ring-1"
                    >
                      Cancel
                    </PopoverButton>
                    <PopoverButton
                      as="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        saveTimerange(CUSTOM_DATE);
                        setCustomDateSelected(false);
                      }}
                      className="ml-3 justify-center rounded-md bg-purple-500 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Apply
                    </PopoverButton>
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        )}
      </div>
    </div>
  );
};
