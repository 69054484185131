import humps from "humps";
import { IPagy } from "core/types/Pagy";
import { IAudience } from "core/types/Audience";
import { baseQuery } from "core/initializers/rtk";
import { ViewLocation } from "core/hooks/useViews";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IViewInsight } from "./viewInsights";

import type { SnakeCasedPropertiesDeep } from "type-fest";
export interface IView {
  id: string;
  appId: number;
  name: string;
  viewInsights: IViewInsight[];
  pinned: boolean;
  emoji?: string;
  updatedAt: string;
  createdAt: string;
  audience: IAudience;
  useInternalUsers: boolean;
}

interface IGetViewsResponse {
  views: IView[];
  pagy: IPagy;
}

export const viewsApi = createApi({
  baseQuery,
  reducerPath: "viewsApi",
  tagTypes: ["Views"],
  endpoints: (builder) => ({
    getView: builder.query<IView, { id: number; appId: number }>({
      query: ({ id, appId }) => ({
        url: `/views/${id}`,
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["Views"],
      transformResponse: (response: SnakeCasedPropertiesDeep<IView>): IView =>
        humps.camelizeKeys(response) as IView,
    }),
    getViews: builder.query<
      IGetViewsResponse,
      {
        appId: number;
        location: ViewLocation;
        pinned?: boolean;
        page?: number;
        query?: string;
      }
    >({
      query: ({ appId, location, query, page = 1, pinned = true }) => ({
        url: `/views`,
        params: humps.decamelizeKeys({ appId, location, query, page, pinned }),
      }),
      providesTags: ["Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IGetViewsResponse>,
      ): IGetViewsResponse => humps.camelizeKeys(response) as IGetViewsResponse,
    }),
    addView: builder.mutation<
      void,
      {
        appId: number;
        name?: string;
        template?: string;
        templateParams?: any;
        pin: boolean;
      }
    >({
      query: ({ appId, name, template, templateParams, pin }) => ({
        url: `/views`,
        method: "POST",
        params: humps.decamelizeKeys({
          appId,
          name,
          template,
          templateParams: JSON.stringify(templateParams),
          pin,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["Views"],
    }),
    deleteView: builder.mutation<{}, { appId: number; id: number }>({
      query: ({ appId, id }) => ({
        url: `/views/${id}`,
        method: "DELETE",
        params: humps.decamelizeKeys({ appId, id }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IView[]>,
      ): IView[] => humps.camelizeKeys(response) as IView[],
    }),
    updateView: builder.mutation<
      {},
      {
        appId: number;
        id: number;
        order?: number;
        emoji?: string;
        name?: string;
        useInternalUsers?: boolean;
        audienceId?: number;
      }
    >({
      query: ({
        appId,
        id,
        order,
        emoji,
        name,
        useInternalUsers,
        audienceId,
      }) => ({
        url: `/views/${id}`,
        method: "PUT",
        params: humps.decamelizeKeys({
          appId,
          id,
          order,
          emoji,
          name,
          useInternalUsers,
          audienceId,
        }),
        credentials: "include",
        mode: "cors",
      }),
      invalidatesTags: ["Views"],
      transformResponse: (
        response: SnakeCasedPropertiesDeep<IView[]>,
      ): IView[] => humps.camelizeKeys(response) as IView[],
    }),
    pinView: builder.mutation<
      void,
      { appId: number; viewId: number; pinned: boolean }
    >({
      query: ({ appId, viewId, pinned }) => ({
        url: `/views/${viewId}/pin`,
        method: "PUT",
        body: humps.decamelizeKeys({
          appId,
          id: viewId,
          pinned,
        }),
      }),
      invalidatesTags: ["Views"],
    }),
  }),
});

export const {
  useLazyGetViewQuery,
  useGetViewsQuery,
  useAddViewMutation,
  useDeleteViewMutation,
  useUpdateViewMutation,
  usePinViewMutation,
} = viewsApi;
