import { SnakeCasedProperties } from "type-fest";
import humps from "humps";
import { baseQuery } from "core/initializers/rtk";
import { utf8ToBase64 } from "core/helpers/insights/utf8ToBase64";
import { createApi } from "@reduxjs/toolkit/query/react";

interface IInsightTokenResponse {
  id: number;
  token: string;
  insightId: number;
}

interface IInsightTokenArgs {
  appId: number;
  reportId: number;
  insightType: number;
}

interface IGetInsightArgs {
  appId: number;
  reportId: number;
  groupId?: string;
  audienceId?: number;
  groupType: "user" | "company" | "group";
  timerangeType: number;
  timerangeValue: number;
  timerangeStartTimestamp: string;
  timerangeEndTimestamp: string;
  config: Record<string, any>;
  audience: Record<string, any>;
  insightType: number;
  insightParams?: Record<string, any>;
  sharingSecretToken?: string;
  refreshCache?: boolean;
}

export const insightsApi = createApi({
  baseQuery,
  reducerPath: "insightsApi",
  tagTypes: ["Token", "Insight", "Report"],
  endpoints: (builder) => ({
    getInsight: builder.query<any, IGetInsightArgs>({
      query: ({
        appId,
        reportId,
        groupId,
        audienceId,
        groupType,
        timerangeType,
        timerangeValue,
        timerangeStartTimestamp,
        timerangeEndTimestamp,
        config,
        audience,
        insightType,
        insightParams = {},
        sharingSecretToken = null,
        refreshCache = false,
      }) => ({
        url: "/report/insight",
        method: "POST",
        body: {
          app_id: appId,
          report_id: reportId,
          group_id: groupId,
          audience_id: audienceId,
          group_type: groupType,
          timerange_type: timerangeType,
          timerange_value: timerangeValue,
          timerange_start_timestamp: timerangeStartTimestamp,
          timerange_end_timestamp: timerangeEndTimestamp,
          config: utf8ToBase64(JSON.stringify(humps.decamelizeKeys(config))),
          audience: utf8ToBase64(
            JSON.stringify(humps.decamelizeKeys(audience)),
          ),
          sharing_secret_token: sharingSecretToken,
          insight_type: insightType,
          insight_params: JSON.stringify(humps.decamelizeKeys(insightParams)),
          refresh_cache: refreshCache,
        },
      }),
      providesTags: ["Insight", "Report"],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getInsights: builder.query<any, { appId: number }>({
      query: ({ appId }) => ({
        url: "/report/insight/insights",
        params: humps.decamelizeKeys({ appId }),
      }),
      providesTags: ["Insight", "Report"],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    getInsightToken: builder.query<IInsightTokenResponse, IInsightTokenArgs>({
      query: ({ appId, reportId, insightType }) => ({
        url: "/report/insight/token",
        params: {
          app_id: appId,
          report_id: reportId,
          insight_type: insightType,
        },
      }),
      providesTags: ["Token"],
      transformResponse: (
        response: SnakeCasedProperties<IInsightTokenResponse>,
      ) => humps.camelizeKeys(response) as IInsightTokenResponse,
    }),
    shareInsight: builder.mutation({
      query: ({ appId, reportId, insightType, insight_id }) => ({
        url: "/report/insight/share",
        method: "PUT",
        body: {
          app_id: appId,
          report_id: reportId,
          insight_type: insightType,
          insight_id: insight_id,
        },
      }),
      invalidatesTags: ["Token"],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
    removePublicAccess: builder.mutation({
      query: ({ appId, reportId, insightType }) => ({
        url: "/report/insight/delete_token",
        method: "DELETE",
        body: { app_id: appId, report_id: reportId, insight_type: insightType },
      }),
      invalidatesTags: ["Token"],
      transformResponse: (response: any) => humps.camelizeKeys(response),
    }),
  }),
});

export const {
  useLazyGetInsightQuery,
  useLazyGetInsightsQuery,
  useGetInsightsQuery,
  useGetInsightQuery,
  useGetInsightTokenQuery,
  useShareInsightMutation,
  useRemovePublicAccessMutation,
} = insightsApi;
