import { Navigate, useParams } from "react-router-dom";
import React from "react";
import { EnabledAlerts } from "modules/Settings/Slack/EnabledAlerts";
import useRequest from "core/hooks/useRequest";
import TeamIcon from "core/design-system/components/Icon/Interface/TeamIcon";
import KeepControlIcon from "core/design-system/components/Icon/Interface/KeepControlIcon";
import InstantIcon from "core/design-system/components/Icon/Interface/InstantIcon";
import CelebrateIcon from "core/design-system/components/Icon/Interface/CelebrateIcon";
import { SLACK_REDIRECT_URL_KEY } from "core/constants/integrations";
import { Box, Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import UninstallButton from "../../../modules/Settings/Integrations/Slack/UninstallButton";
import * as Integration from "../../../modules/Settings/Integrations/Integration";
import { LogoType } from "../../../core/design-system/components/Icon/Icon.types";

const CONTENT: Integration.IntegrationMetadata = {
  title: "Slack",
  description: "Get notifications and alerts from your reports",
  logo: "companySlackLogo" as LogoType,
  docsUrl:
    "https://help.june.so/en/articles/4803772-receive-a-report-digest-in-slack",
};

export const Slack: React.FC = () => {
  const { appId } = useParams();
  const {
    data: slackApp,
    isLoading,
    mutate,
  } = useRequest({
    url: `/slack_apps`,
    params: { appId },
    withCredentials: true,
  });
  const onConnectSlack = () => {
    localStorage.setItem(
      SLACK_REDIRECT_URL_KEY,
      `/a/${appId}/integrations/slack`,
    );
    window.location.replace(`${import.meta.env.VITE_API_HOST}/auth/slack`);
  };

  if (window.location.pathname.includes("settings/integrations/slack")) {
    return <Navigate to={`/a/${appId}/integrations/slack`} />;
  }

  const isConnected = slackApp?.length > 0;

  return (
    <>
      <Integration.Container isExpanded>
        <Integration.Header
          metadata={CONTENT}
          ActionComponent={() =>
            isConnected ? (
              <UninstallButton mutate={mutate} appId={appId} />
            ) : (
              <Button onClick={onConnectSlack} colorScheme="purple">
                Setup the integration
              </Button>
            )
          }
        />
        <Integration.Body>
          <Integration.Section
            title={"Slack settings"}
            description={"Configure how do you send alerts to your Slack"}
          >
            {isLoading ? (
              <Spinner />
            ) : isConnected ? (
              <EnabledAlerts />
            ) : (
              <Flex gridGap={2} align="center">
                <Flex direction="column" gridGap={4}>
                  <Flex direction="row" gridGap={4}>
                    <Flex>
                      <InstantIcon color="primary" h={7} w={7} />
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="sm" fontWeight="bold">
                        Instant notifications
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Get instant alerts when a user does something in your
                        product
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction="row" gridGap={4}>
                    <Flex>
                      <CelebrateIcon h={7} w={7} />
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="sm" fontWeight="bold">
                        Celebrate when milestones are hit
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Get notified when a user signs up or converts to paid.
                        The possibilities are endless.
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction="row" gridGap={4}>
                    <Flex>
                      <KeepControlIcon h={7} w={7} />
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="sm" fontWeight="bold">
                        Stay in control when you get alerted
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Control when to receive alerts. Choose from daily,
                        weekly, monthly or simply in real time.
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex direction="row" gridGap={4}>
                    <Flex>
                      <TeamIcon color="purple.500" h={7} w={7} />
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="sm" fontWeight="bold">
                        Rally your team behind your most important metrics
                      </Text>
                      <Text fontSize="sm" fontWeight="sm" color="gray.600">
                        Get everyone up to speed with what's happening inside
                        your company.
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex w="100%">
                  <Center w="100%">
                    <Box
                      borderRadius="lg"
                      h="400px"
                      w="100%"
                      bgImage={`url('/slack_illustration.png')`}
                      bgRepeat="no-repeat"
                      bgPosition="center"
                      bgSize="contain"
                    />
                  </Center>
                </Flex>
              </Flex>
            )}
          </Integration.Section>
        </Integration.Body>
      </Integration.Container>
    </>
  );
};
